import { ServiceOption } from '../../../core/options/serviceOption';
import { Post } from '../../../core/services/serviceBase';
import { TouristPlaceModel } from './models';

const API_URL = process.env.REACT_APP_API_URL
export const GET_TOURIST_PLACE_URL = `${API_URL}/Support/GetTouristPlace`

export async function getTourstPlace( cityCode: string, value: string) {
    const response = await Post(GET_TOURIST_PLACE_URL, { cityCode, value })
    let cityData = {} as Array<TouristPlaceModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        cityData = response.responseData;
    }
    
    return cityData
}