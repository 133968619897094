import { useEffect } from 'react';
import Header from '../base/Header';
import Footer from "../base/Footer";
import { 
    Autocomplete,
    Box,
    Button, 
    CircularProgress, 
    Container, 
    Dialog, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Divider, 
    Grid, 
    IconButton, 
    InputBase, 
    Paper, 
    TextField } from '@mui/material';
import React from 'react';
import { CityModel } from '../base/core/models';
import { getCity } from '../base/core/service';
import { closeLoading, showLoading } from '../loading/LoadingScreen';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import SearchIcon from '@mui/icons-material/Search';
import './css/AttractionPlace.css'
import { TouristPlaceModel } from './core/models';
import { getTourstPlace } from './core/service';
import CloseIcon from '@mui/icons-material/Close';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

declare global {
    interface Window {
        loadFile: (params: any) => void;
    }
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
  
      return (
          <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
              {children}
              {onClose ? (
                  <IconButton
                      aria-label="close"
                      onClick={onClose}
                      sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                      }}
                      >
                  <CloseIcon />
              </IconButton>
              ) : null}
          </DialogTitle>
      );
  }

function AttractionPlacePage() {
    const [cityData, setCityData] = React.useState<Array<CityModel>>();
    const getOptionCityLabel = (option: CityModel) => option.cityName;
    const getOptionCityValue = (option: CityModel, value: any) => option.cityCode === value.cityCode || value === "";
    const [cityValue, setCityValue] = React.useState<CityModel | null>(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [touristPlaceData, setTouristPlaceData] = React.useState<Array<TouristPlaceModel>>();
    const [openTourstPlace, setOpenTourstPlace] = React.useState(false);
    const [touristPlace, setTouristPlace] = React.useState<TouristPlaceModel>();
    const [openSearchImage, setOpenSearchImage] = React.useState(false);
    const [sourceSearchImage, setSourceSearchImage] = React.useState(null);

    const handleClickOpenSearchImage = () => {
        setSourceSearchImage(null);
        setOpenSearchImage(true);
    };

    const handleCloseSearchImage = () => {
        setOpenSearchImage(false);
    };

    const handleClickOpenTourstPlace = (data: any) => {
        setTouristPlace(data);
        setOpenTourstPlace(true);
    };

    const handleCloseTourstPlace = () => {
        setOpenTourstPlace(false);
    };
    
    useEffect(() => {
        showLoading();
        getCity().then((response) => {
            setCityData(response);
            closeLoading();
        })
    },[])

    const search = () => {
        showLoading();
        let city = cityValue ? cityValue?.cityCode : "";

        console.log(city)
        console.log(searchValue)


        getTourstPlace(city, searchValue).then((response) => {
            setTouristPlaceData(response);
            closeLoading();
        })
    }

    const handleUploadClick = (event: any) => {
        let divResult = document.getElementById('label-container');
        if(divResult){
            divResult.innerHTML = 'Loading..';
        }

        window.loadFile(event);
        setSourceSearchImage(event.target.files[0]);
    };

    return (
        <div>
            <Header isHome={false} page='attraction-place'/>
            <div style={{height: '68px', backgroundColor: 'rgb(41, 50, 60)'}}></div>
            <Container style={{marginTop: '20px', marginBottom: '430px'}}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Box sx={{ backgroundColor: 'white', flexGrow: 1, marginTop: '30px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px', borderRadius: '8px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        {cityData && cityData?.length > 0 &&
                                            <Autocomplete
                                                sx={{height: '47px'}}
                                                options={cityData}
                                                value={cityValue}
                                                getOptionLabel={getOptionCityLabel}
                                                isOptionEqualToValue={getOptionCityValue}
                                                onChange={(event: any, newValue: CityModel | null) => {
                                                    setCityValue(newValue)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Địa điểm"/>
                                                )}
                                            />
                                        } 
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Paper
                                            component="form"
                                            sx={{ p: '1px 4px', display: 'flex', alignItems: 'center', border: '1px solid #c3c2c2', boxShadow: 'none'}}
                                        >
                                            <InputBase
                                                sx={{ ml: 1, flex: 1}}
                                                id="txt-search-value"
                                                value={searchValue}
                                                placeholder="Tìm kiếm địa điểm"
                                                onChange={(e) => setSearchValue(e.target.value)}
                                                inputProps={{ 'aria-label': 'Tìm kiếm địa điểm' }}
                                            />
                                            <Divider sx={{ height: 28, m: 0.5, backgroundColor: '#000000' }} orientation="vertical" />
                                            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={search}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ backgroundColor: 'white', flexGrow: 1, marginTop: '30px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px', borderRadius: '8px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Button variant="outlined" 
                                            size="large" 
                                            sx={{textTransform: 'none', marginTop: '2px', lineHeight: 2}} 
                                            startIcon={<ImageSearchIcon />}
                                            onClick={handleClickOpenSearchImage}
                                        >
                                            Tìm kiếm bằng hình ảnh
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Grid container>
                        <Grid item xs={12} md={2}></Grid>
                        { touristPlaceData && touristPlaceData?.length > 0 &&
                            <Grid style={{ padding: '30px 50px', backgroundColor: 'white', borderRadius: '10px', marginTop: '40px'}} item xs={12} md={8}>
                                <h3 style={{textAlign: 'left', color: 'rgb(250 177 62)', fontWeight: 'bold'}}>Kết quả tìm kiếm</h3>
                                    {
                                        touristPlaceData.map((tourstPlace: TouristPlaceModel) => (
                                            <div key={tourstPlace.touristPlaceID} style={{ display: 'flex', textAlign: 'left', marginTop: '40px', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <div style={{maxWidth: '70%'}}>
                                                    <Button startIcon={<FmdGoodIcon />} onClick={() => handleClickOpenTourstPlace(tourstPlace)} size="small"><b>{tourstPlace.touristPlaceName}</b></Button>
                                                    <div><b>Địa điểm: </b><span>{tourstPlace.address}</span></div>
                                                    <div><b>Số điện thoại: </b><span>{tourstPlace.phoneNumber}</span></div>
                                                </div>
        
                                                { tourstPlace.touristPlaceImages.length > 0 &&
                                                    <div>
                                                        <img src={tourstPlace.touristPlaceImages[0].imageUrl} alt="Image" width='80px' height='60px' style={{borderRadius: '8px', marginLeft: '5px', marginTop: '10px'}}/>
                                                        <img src={tourstPlace.touristPlaceImages[1].imageUrl} alt="Image" width='80px' height='60px' style={{borderRadius: '8px', marginLeft: '5px', marginTop: '10px'}}/>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                            </Grid>
                        }
                        <Grid item xs={12} md={2}></Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer/>
            <Dialog
                open={openTourstPlace}
                onClose={handleCloseTourstPlace}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {touristPlace &&
                    <>
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseTourstPlace}>
                            <b>{touristPlace.touristPlaceName}</b>
                        </BootstrapDialogTitle>
                        <DialogContent style={{width: '400px' }}>
                            <DialogContentText id="alert-dialog-description">
                                <img style={{width: '350px', borderRadius: '8px', height: '200px'}} src={touristPlace.touristPlaceImages[0].imageUrl}></img>
                                <div style={{marginTop: '25px'}}>
                                    <span><b>Số điện thoại:</b> {touristPlace.phoneNumber}</span>
                                </div>
                                <div style={{marginTop: '8px'}}>
                                    <span><b>Địa chỉ:</b> {touristPlace.address}</span>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <span>{touristPlace.content}</span>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </>
                }
            </Dialog>
            <Dialog
                open={openSearchImage}
                onClose={handleCloseSearchImage}
                aria-labelledby="alert-dialog-title-search"
                aria-describedby="alert-dialog-description-search"
            >
                <>
                    <BootstrapDialogTitle id="customized-dialog-title-search" onClose={handleCloseSearchImage}>
                        <b>Tìm kiếm bằng hình ảnh</b>
                    </BootstrapDialogTitle>
                    <DialogContent style={{width: '400px' }}>
                        <DialogContentText id="alert-dialog-description-search">
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                { sourceSearchImage && 
                                    <img style={{ marginBottom: '20px'}} id="imagePreview" width="100%" height="260px"/>
                                }
                            </Grid>
                            <input type="file" accept="image/*" id="contained-button-file" multiple onChange={handleUploadClick}/>
                        </Grid>
                        <div style={{ display: 'flex', marginTop: '30px', justifyContent: 'center' }}  id="label-container"></div>
                        </DialogContentText>
                    </DialogContent>
                </>
            </Dialog>
        </div>
    );
}

export default AttractionPlacePage;