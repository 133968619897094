import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/Header.css'
import { useAppSelector } from '../..';
import { IsEmpty } from '../../core/utilities/functionBase';
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

interface IHeader {
    isHome: boolean
    page: string
}

function Header(header: IHeader) {
    const userData = useAppSelector((state) => state.user)
    const [btnActiveHamburger, setbtnActionHamburger] = useState(false);
    const isHome = header.isHome

  useEffect(() => {
      const hamburger: any = document.querySelector('.header .nav-bar .nav-list .hamburger');
      const mobile_menu: any = document.querySelector('.header .nav-bar .nav-list ul');
      const menu_item: any = document.querySelectorAll('.header .nav-bar .nav-list ul li a');
      const header: any = document.querySelector('.header.navbar');

      document.addEventListener('scroll', () => {
        var scroll_position = window.scrollY;
        if (scroll_position > 250 || !isHome) {
          header.style.backgroundColor = '#29323c';
        } else {
          header.style.backgroundColor = 'transparent';
        }
      });

      menu_item.forEach((item: any) => {
        item.addEventListener('click', () => {
          hamburger.classList.toggle('active');
          mobile_menu.classList.toggle('active');
        });
      });
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <section id="header">
        <div className="header navbar">
          <div className="nav-bar">
            <div className="brand">
              <a className="#hero">
                <img src='/media/images/logo.png' height='45px'></img>
              </a> 
            </div>
            <div className='nav-list'>
              <div className={btnActiveHamburger ? 'hamburger active' : 'hamburger'} onClick={() => setbtnActionHamburger(!btnActiveHamburger)}>
                <div className="bar"></div>
              </div>
              <ul className={btnActiveHamburger ? 'active header-menu' : 'header-menu'}>
                <li className='header-menu-option'>
                  <Link className='link-nav-bar' 
                        style={ header.page === "home" ? {color: 'rgb(27, 236, 247)'} : {}} 
                        to="/home">
                          Trang chủ
                  </Link>
                </li>
                <li className='header-menu-option'>
                  <Link className='link-nav-bar'
                        style={ header.page === "booking-room" ? {color: 'rgb(27, 236, 247)'} : {}} 
                        to="/booking-room">
                          Đặt phòng
                  </Link>
                </li>
                <li className='header-menu-option'>
                  <Link className='link-nav-bar'
                        style={ header.page === "rental" ? {color: 'rgb(27, 236, 247)'} : {}} 
                        to="/rental">
                          Đặt dịch vụ
                  </Link>
                </li>
                <li className='header-menu-option'>
                  <Link className='link-nav-bar'
                        style={ header.page === "payment" ? {color: 'rgb(27, 236, 247)'} : {}} 
                        to="/payment">
                          Thanh toán
                  </Link>
                </li>
                <li className='header-menu-option'>
                  <Link className='link-nav-bar'
                        style={ header.page === "service-us" ? {color: 'rgb(27, 236, 247)'} : {}} 
                        to="/service-us">
                          Dịch vụ
                  </Link>
                </li>
                <li className='header-menu-option'>
                  <Link className='link-nav-bar'
                        style={ header.page === "about-us" ? {color: 'rgb(27, 236, 247)'} : {}} 
                        to="/about-us">
                          Chúng tôi
                  </Link>
                </li>
                <li className='header-menu-option'>
                  <Link className='link-nav-bar'
                        style={ header.page === "attraction-place" ? {color: 'rgb(27, 236, 247)'} : {}} 
                        to="/attraction-place">
                          Địa điểm
                  </Link>
                </li>

                <li className='header-menu-option'>
                  <Button
                    className='auth-nav-bar-button'
                    id="auth-nav-bar-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon style={{fontSize: '30px'}}/>}
                  >
                    {IsEmpty(userData) ? "Login | Sign up" : "Hi, " + userData.lastName}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'auth-nav-bar-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {IsEmpty(userData) 
              ?
                <div>
                  <MenuItem>
                    <ListItemIcon>
                      <LoginIcon fontSize="small" />
                    </ListItemIcon>
                    <Link className='auth-nav-bar' to="/login">Log In</Link>
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <PersonAddAltIcon fontSize="small" />
                    </ListItemIcon>
                    <Link className='auth-nav-bar' to="/sign-up">Sign up</Link>
                  </MenuItem>
                </div>
              :
              <div>
                <MenuItem>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <Link className='auth-nav-bar' to="/logout">Log Out</Link>
                </MenuItem>
              </div>
            }
          </Menu>
        </div>
      </section>

  );
}

export default Header;
