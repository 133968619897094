import { useEffect } from 'react';
import Aos from 'aos';
import Header from '../base/Header';
import Footer from "../base/Footer";
import { 
    Box,
    Button, 
    Card, 
    CardActions, 
    CardContent, 
    CardMedia, 
    Container, 
    FormControl, 
    Grid, 
    InputLabel, 
    Link, 
    MenuItem, 
    Select, 
    SelectChangeEvent, 
    TextField, 
    Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import './css/Room.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { CityModel, TouristAttractionModel } from '../base/core/models';
import { getCity, getTouristAttraction } from '../base/core/service';
import { RoomModel } from './core/models';
import { searchRoom } from './core/service';
import { closeLoading, showLoading } from '../loading/LoadingScreen';

const getStar = (star: number) => {
    switch(star){
        case 1: 
            return <div style={{marginLeft: '10px', marginBottom: '7px'}}>⭐️</div>
        case 2:
            return <div style={{marginLeft: '10px', marginBottom: '7px'}}>⭐️ ⭐️</div>
        case 3: 
            return <div style={{marginLeft: '10px', marginBottom: '7px'}}>⭐️ ⭐️ ⭐️</div>
        case 4:
            return <div style={{marginLeft: '10px', marginBottom: '7px'}}>⭐️ ⭐️ ⭐️ ⭐️</div>
        case 5: 
            return <div style={{marginLeft: '10px', marginBottom: '7px'}}>⭐️ ⭐️ ⭐️ ⭐️ ⭐️</div>
        default:
            return <div style={{marginLeft: '10px', marginBottom: '7px'}}>⭐️ ⭐️ ⭐️</div>
    }
}

function Room() {
    const locationUrl = useLocation()
    let params: any
    params = locationUrl.state
    const navigator = useNavigate()

    const [cityData, setCityData] = React.useState<Array<CityModel>>();
    const [txtCheckInDate, setTxtCheckInDate] = React.useState<Dayjs | null>(dayjs());
    const [txtCheckOutDate, setTxtCheckOutDate] = React.useState<Dayjs | null>(dayjs().add(2, 'day'));
    const [txtLocation, setTxtLocation] = React.useState("054");
    const [txtPeople, setTxtPeople] = React.useState("2");
    const [topTouristAttractionData, setTopRoomData] = React.useState(Array<TouristAttractionModel>);
    const [searchRoomData, setSearchRoomData] = React.useState(Array<RoomModel>);
    
    useEffect(() => {
        showLoading()
        Aos.init({duration: 2000})

        if(params){
            setTxtPeople(params.people)
            setTxtLocation(params.location)
            setTxtCheckInDate(dayjs(params.checkInDate, "DD/MM/YYYY"))
            setTxtCheckOutDate(dayjs(params.checkOutDate, "DD/MM/YYYY"))

            let checkIn = dayjs(params.checkInDate, "DD/MM/YYYY").format('YYYYMMDD')
            let checkOut = dayjs(params.checkOutDate, "DD/MM/YYYY").format('YYYYMMDD')

            console.log(checkIn);
            console.log(checkOut);

            searchRoom (
                params.location, 
                params.people, 
                checkIn, 
                checkOut)
            .then((response) => {
                setSearchRoomData(response)
            })
        }

        getCity().then((response) => {
            setCityData(response)
        })

        getTouristAttraction().then((response) => {
            setTopRoomData(response)
            closeLoading()
        })
    },[])

    const search = () => {
        showLoading()
        let checkIn = dayjs(txtCheckInDate).format('YYYYMMDD')
        let checkOut = dayjs(txtCheckOutDate).format('YYYYMMDD')
        searchRoom(txtLocation, txtPeople, checkIn, checkOut).then((response) => {
            setSearchRoomData(response)
            closeLoading()
        })
    }

    const showRoomDetail = (roomID: number) => {
        navigator(`/booking-room/detail/${roomID}`)
    }

    return (
        <div>
            <Header isHome={false} page='booking-room'/>
            <div style={{height: '68px', backgroundColor: 'rgb(41, 50, 60)'}}></div>

            <Container style={{marginTop: '40px', marginBottom: '100px'}}>
                <div style={{
                    textAlign: 'left',
                    borderBottom: '2px solid',
                    borderBottomColor: '#a19590'
                }}>
                    <b>Đặt phòng ngay</b>
                </div>
                <Box sx={{ flexGrow: 1, marginTop: '50px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '8px'}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '45%', marginRight: '5%'}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Ngày nhận"
                                                value={txtCheckInDate}
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(newValue) => {
                                                    setTxtCheckInDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div style={{width: '45%', marginLeft: '5%'}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Ngày trả"
                                                value={txtCheckOutDate}
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(newValue) => {
                                                    setTxtCheckOutDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '45%', marginRight: '5%'}}>
                                        <FormControl fullWidth sx={{ mt: 5}}>
                                            <InputLabel>Địa điểm</InputLabel>
                                            <Select
                                                value={txtLocation}
                                                variant="standard"
                                                labelId="demo-select-small1"
                                                label="Age"
                                                onChange={(event: SelectChangeEvent) => {
                                                    setTxtLocation(event.target.value);
                                                }}
                                            >
                                                {cityData && cityData?.length > 0 &&
                                                    cityData.map((city: CityModel) => 
                                                        (<MenuItem key={city.cityID} value={city.cityCode}>{`${city.cityCode} - ${city.cityName}`}</MenuItem>))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{width: '45%', marginLeft: '5%'}}>
                                        <FormControl fullWidth sx={{ mt: 5}}>
                                            <InputLabel>Số người</InputLabel>
                                            <Select
                                                value={txtPeople}
                                                variant="standard"
                                                labelId="demo-select-small"
                                                label="Age"
                                                onChange={(event: SelectChangeEvent) => {
                                                    setTxtPeople(event.target.value);
                                                }}
                                            >
                                                <MenuItem value={1}>1 người</MenuItem>
                                                <MenuItem value={2}>2 người</MenuItem>
                                                <MenuItem value={4}>4 người</MenuItem>
                                                <MenuItem value={6}>6 người</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <Button style={{marginTop: '40px'}} className="btn-booking" variant="contained" onClick={search}>
                                    Tìm kiếm
                                </Button>
                            </div>
                            <div className='top-search'>
                                <div style={{borderBottom: '2px solid rgb(188 190 190)', margin: '30px 20px', textAlign: 'left', fontSize: '20px'}}>Những địa điểm nổi tiếng</div>
                                <div>
                                    {topTouristAttractionData && topTouristAttractionData?.length > 0 &&
                                        topTouristAttractionData.slice(0, 3).map((tourist: TouristAttractionModel) => (
                                            <Card className="card-top-search" key={tourist.touristAttractionID}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 151 }}
                                                    image={tourist.imageUrl}
                                                    alt="Live from space album cover"
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                                        <Typography component="div" variant="h6">
                                                            <Link className="card-top-search-title" href={tourist.url} target="_blank">
                                                                {tourist.touristAttractionName.toUpperCase()}
                                                            </Link>
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                            {cityData && cityData?.length > 0 &&
                                                                cityData?.find(item => item.cityCode == tourist.cityCode)?.cityName
                                                            }
                                                        </Typography>
                                                    </CardContent>
                                                </Box>
                                            </Card>
                                        ))
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div style={{borderBottom: '2px solid rgb(188 190 190)', margin: '30px 20px', textAlign: 'left', fontSize: '20px'}}>Kết quả tìm kiếm</div>
                            <Box sx={{ flexGrow: 1, padding: '20px'}}>
                                <Grid container spacing={5}>
                                    {!searchRoomData || searchRoomData?.length <= 0 &&
                                        <div style={{ margin: 'auto', marginTop: '50px'}}><p>Không tìm thấy phòng</p></div>
                                    }
                                    {searchRoomData && searchRoomData?.length > 0 &&
                                        searchRoomData.map((room: RoomModel) => (
                                            <Grid key={room.roomID} style={{display: 'flex', justifyContent: 'center'}} item xs={12} sm={6} md={6} >
                                                <Card className="card-result-found">
                                                    <div style={{
                                                        width: '100%',
                                                        height: '140px',
                                                        overflow: 'hidden',
                                                        margin: '0 auto'
                                                    }}>
                                                        <img src={room.roomImages[0].imageUrl} height="140px" width="100%"></img>
                                                    </div>
                                                    <CardContent>
                                                        <Typography className="card-result-found-title" gutterBottom variant="h6" component="div">
                                                            <Link className="card-top-search-title" href={`/booking-room/detail/${room.roomID}`} target="_blank">{room.roomName.toUpperCase()}</Link>
                                                        </Typography>
                                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                                            <div>
                                                                <LocationOnIcon fontSize='small' style={{marginBottom: '7px'}}/>
                                                                <span>{room.cityName}</span>
                                                            </div>
                                                            <div style={{width: '20px'}}></div>
                                                            <div style={{marginBottom: '1px'}}>{room.people } người</div>
                                                        </div>
                                                        <div style={{marginTop: '2px'}}><b>{room.displayPrice} VND</b></div>
                                                    </CardContent>
                                                    <CardActions style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        {getStar(room.star)}
                                                        <Button size="small" variant="outlined" onClick={() => showRoomDetail(room.roomID)}>Chi tiết</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Grid>  
                    </Grid>
                </Box>
            </Container>
            <Footer/>
        </div>
    );
}

export default Room;