import { useEffect, useRef } from 'react';
import React from 'react';
import './css/Room.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/swiper-bundle.min.css';
import Header from '../base/Header';
import Footer from "../base/Footer";
import Aos from 'aos';
import { 
    Box, 
    Button, 
    Container, 
    Dialog, 
    DialogActions,
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControl, 
    FormControlLabel, 
    FormLabel, 
    Grid, 
    Radio, 
    RadioGroup, 
    TextField} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import {ProductImagesSlider, RentalSlider} from './ProductImagesSlider';
import { queryRoom, searchTouristPlaces, validate } from './core/service';
import { RoomModel, TouristPlaceModel } from './core/models';
import { AuthModel } from '../auth/core/models';
import { getAuth } from '../auth/core/authHelpers';
import { useAppSelector } from '../..';
import { closeLoading, showLoading } from '../loading/LoadingScreen';
import { IsEmpty } from '../../core/utilities/functionBase';
import { PaymentOrder } from '../payment/PaymentOrder';

type RefHandler = {
    handleClickOpen: (
        user: number,
        orderID: number,
        branchID: number,
        orderType: string,
        fullName: string,
        email: string,
        phoneNumber: string,
        orderName: string,
        dateCheckIn: string,
        dateCheckOut: string,
        dateCheckInDisplay: string,
        dateCheckOutDisplay: string,
        description: string,
        count: number,
        totalAmount: number,
        paymentMethod: string
    ) => void
}

function RoomDetail() {
    const navigator = useNavigate()
    const childRef = useRef() as React.MutableRefObject<RefHandler>;
    const params: any = useParams();
    const [roomData, setRoomData] = React.useState<RoomModel>();
    const [touristPlaces, setTouristPlaces] = React.useState<Array<TouristPlaceModel>>();
    const authData: AuthModel | undefined = getAuth();
    const userData = useAppSelector((state) => state.user);

    const [txtFullName, setTxtFullname] = React.useState("");
    const [txtPhoneNumber, setTxtPhoneNumber] = React.useState("");
    const [txtEmail, setTxtEmail] = React.useState("");
    const [txtCheckInDate, setTxtCheckInDate] = React.useState<Dayjs | null>(null);
    const [txtCheckOutDate, setTxtCheckOutDate] = React.useState<Dayjs | null>(null);
    const [txtDescription, setTxtDescription] = React.useState("");
    const [optPayment, setOptPayment] = React.useState('online');

    const [isErrorBooking, setErrorBooking] = React.useState(false);
    const [txtErrorBooking, setTxtErrorBooking] = React.useState("");
    const [open, setOpen] = React.useState(false);
   

    const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOptPayment((event.target as HTMLInputElement).value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigator("/login")
    };

    useEffect(() => {
        showLoading()
        Aos.init({duration: 2000})

        if(params){
          queryRoom(params.roomID).then((response) => {
            setRoomData(response)
            searchTouristPlaces(response.cityCode).then((response) => {
              setTouristPlaces(response)
              closeLoading()
            })
          })
        }
    }, [])

    const submitBooking = () => {

        let checkInDisplay = dayjs(txtCheckInDate).format('DD/MM/YYYY')
        let checkOutDisplay = dayjs(txtCheckOutDate).format('DD/MM/YYYY')
        let checkIn = dayjs(txtCheckInDate).format('YYYYMMDD')
        let checkOut = dayjs(txtCheckOutDate).format('YYYYMMDD')

        if(!authData || IsEmpty(userData)){
            handleClickOpen()
            return
        }

        if(!txtFullName || !txtPhoneNumber || !txtEmail || !checkIn || !checkOut){
            setErrorBooking(true)
            setTxtErrorBooking("Vui lòng điền đầy đủ thông tin")
            return;
        }

        if(dayjs(txtCheckInDate).format('YYYYMMDD') >= dayjs(txtCheckOutDate).format('YYYYMMDD')){
            setErrorBooking(true)
            setTxtErrorBooking("Vui lòng kiểm tra ngày nhận và trả phòng")
            return;
        }

        var Difference_In_Time = dayjs(txtCheckOutDate).toDate().getTime() - dayjs(txtCheckInDate).toDate().getTime();
        var totalDays = Difference_In_Time / (1000 * 3600 * 24);

        if(roomData) {
            validate(roomData.roomID, checkIn, checkOut).then((response) => {
                if(response) {
                    childRef.current.handleClickOpen(
                        userData.id,
                        roomData.roomID,
                        roomData.branchID,
                        "ROOM",
                        txtFullName, 
                        txtPhoneNumber, 
                        txtEmail, 
                        roomData.roomName, 
                        checkIn, 
                        checkOut,
                        checkInDisplay,
                        checkOutDisplay,
                        txtDescription, 
                        1,
                        roomData.price * totalDays, 
                        optPayment
                    )
                } else {
                    setErrorBooking(true)
                    setTxtErrorBooking("Hiện tại ngày bạn chọn đã được đặt")
                    return;
                }
            })
        }

        setErrorBooking(false)
        setTxtErrorBooking("")
    }

    return (
        <div>
            <Header isHome={false} page='booking-room'/>
            <div style={{height: '68px', backgroundColor: 'rgb(41, 50, 60)'}}></div>
            <Container style={{marginTop: '30px', marginBottom: '100px'}}>
                <div style={{
                    textAlign: 'left',
                    borderBottom: '2px solid',
                    borderBottomColor: '#a19590'
                }}>
                    <b>Chi tiết phòng</b>
                </div>
                <Box sx={{ flexGrow: 1, marginTop: '50px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <div style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: '#fff',
                                                padding: '10px'
                                            }}>
                                                { roomData &&
                                                    <ProductImagesSlider images={roomData?.roomImages} />
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div style={{textAlign: 'left', marginTop: '20px'}}>
                                            <div style={{fontSize: '24px', color: '#09a9b2'}}>
                                                <b>{roomData?.roomName}</b>
                                            </div>
                                            <div style={{display: 'flex', fontSize: '13px', color: '#b46806'}}>
                                                <span>{roomData?.cityName}</span>
                                                <span style={{ marginLeft: '10px'}}>{roomData?.people} Guest</span>
                                            </div>
                                            <div style={{display: 'flex', marginTop: '20px', fontSize: '15px'}}>
                                                <span><b>Số điện thoại: </b>{roomData?.phoneNumber}</span>
                                            </div>
                                            <div style={{display: 'flex', fontSize: '15px' }}>
                                                <span> 
                                                    <b>Địa chỉ: </b>{roomData?.address}
                                                </span>
                                            </div>
                                            <div style={{display: 'flex', marginTop: '20px', fontSize: '24px', color: 'rgb(242 141 10)'}}>
                                                <b>{roomData?.displayPrice} VND</b>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <div style={{textAlign: 'left', marginTop: '20px'}}>
                                            <span style={{ whiteSpace: "pre-line" }}>{roomData?.content}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{backgroundColor: 'white', padding: '20px 0', borderRadius: '8px'}}>
                                <div style={{ padding: '20px', textAlign: 'center'}}>
                                    <b>Đặt phòng ngay!</b>
                                </div>
                                <div hidden={!isErrorBooking} style={{ marginBottom: '5px', color: 'red' }}>{txtErrorBooking}</div>
                                <div style={{ borderRadius: '8px'}}>
                                    <div style={{width: '90%', marginRight: '5%', marginLeft: '5%'}}>
                                        <TextField 
                                            id="txtFullName" 
                                            fullWidth label="Họ và tên"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setTxtFullname(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%', display: 'flex'}}>
                                        <TextField  style={{marginRight: '10px'}} 
                                            id="txtPhoneNumber" 
                                            fullWidth 
                                            label="Số điện thoại" 
                                            variant="standard"
                                            value={txtPhoneNumber}
                                            inputProps={{ maxLength: 10}}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setTxtPhoneNumber(event.target.value.replace(/\D/g, ''));
                                            }}
                                        />
                                        <TextField style={{marginLeft: '10px'}}
                                            type='email'
                                            id="txtEmail" 
                                            fullWidth label="Email" 
                                            variant="standard"
                                            inputProps={{ maxLength: 100 }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setTxtEmail(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%'}}></div>
                                        <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%', display: 'flex'}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Ngày nhận"
                                                    value={txtCheckInDate}
                                                    inputFormat="DD/MM/YYYY"
                                                    onChange={(newValue) => {
                                                        setTxtCheckInDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth style={{marginRight: '10px'}} variant="standard" {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Ngày trả"
                                                    value={txtCheckOutDate}
                                                    inputFormat="DD/MM/YYYY"
                                                    onChange={(newValue) => {
                                                        setTxtCheckOutDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth style={{marginLeft: '10px'}} variant="standard" {...params} />}
                                                />
                                            </LocalizationProvider>         
                                        </div>
                                        <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%'}}>
                                            <TextField
                                                fullWidth
                                                id="txtDescription"
                                                label="Nội dung"
                                                multiline
                                                rows={4}
                                                variant="standard"
                                                inputProps={{ maxLength: 500 }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setTxtDescription(event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div style={{marginTop: '40px', marginLeft: '18px'}}>
                                            <FormControl>
                                                <FormLabel style={{color: 'rgb(242, 141, 10)'}} id="demo-row-radio-buttons-group-label">Phương thức thanh toán</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={optPayment}
                                                    onChange={handlePaymentChange}
                                                >
                                                    <FormControlLabel value="online" control={<Radio />} label="Thanh toán ngay" />
                                                    <FormControlLabel value="offline" control={<Radio />} label="Thanh toán sau" />
                                                </RadioGroup>
                                            </FormControl>
                                    </div>
                                    <Button style={{marginTop: '30px', width: 'auto'}} 
                                        className="btn-booking" 
                                        variant="contained"
                                        onClick={() => submitBooking()}
                                    >
                                        Đặt phòng
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginBottom: '50px', marginTop: '50px', display: 'flex', flexDirection: 'column'}}>
                        <Grid item xs={12} md={8}>
                            <div style={{borderBottom: '2px solid rgb(213 223 224)', textAlign: 'left', fontSize: '20px'}}><b>Những điểm đến hấp dẫn ở {roomData?.cityName}</b> </div>
                        </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                        {touristPlaces && touristPlaces?.length > 0 &&
                            <RentalSlider slides={touristPlaces} />
                        }
                    </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Bạn chưa đăng nhập?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Vui lòng đăng nhập để thực hiện đặt phòng.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                            Xác nhận
                        </Button>
                    </DialogActions>
                </Dialog>
                <PaymentOrder ref={childRef}></PaymentOrder>
            </Container>
            <Footer/>
      </div>
    );
}

export default RoomDetail;