import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IStoreUser
{
  id: number
  userName: string
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
}

export interface IStoreCity
{
  cityID: number
  cityName: string
  cityCode: string
}

export interface IStoreArrayCity
{
  city: Array<IStoreCity>
}

const initialStateUser = {} as IStoreUser
const initialStateCity = {} as IStoreArrayCity

export const userSlice = createSlice({
    name: 'user',
    initialState: initialStateUser,
    reducers: {
      setUser: (state, action: PayloadAction<IStoreUser>) => {
        state.id = action.payload.id
        state.userName = action.payload.userName
        state.email = action.payload.email
        state.phoneNumber = action.payload.phoneNumber
        state.firstName = action.payload.firstName
        state.lastName = action.payload.lastName
      },
    },
})

export const citySlice = createSlice({
  name: 'city',
  initialState: initialStateCity,
  reducers: {
    setCity: (state, action: PayloadAction<IStoreArrayCity>) => {
      state.city = action.payload.city
    },
  },
})


// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions
export const { setCity } = citySlice.actions


//export default citySlice.reducer
