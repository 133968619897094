import React, {useEffect} from "react";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './css/Home.css'
import './css/Gallery.css'
import './css/Service.css'
import './css/Payment.css'

import { Autocomplete, Button, Container, TextField,} from "@mui/material";
import Aos from 'aos';
import 'aos/dist/aos.css';
import Header from "../base/Header";
import Footer from "../base/Footer";
import Contact from "./Contact";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs';
import { getCity } from "../base/core/service";
import { CityModel } from "../base/core/models";
import { closeLoading, showLoading } from "../loading/LoadingScreen";

const listGuest = [
    { label: '1 người', value: 1 },
    { label: '2 người', value: 2 },
    { label: '4 người', value: 4 },
    { label: '6 người', value: 6 }
]

function Home() {
    const navigate = useNavigate();
    const [cityData, setCityData] = React.useState<Array<CityModel>>();
    const [txtCheckInDate, setTxtCheckInDate] = React.useState<Dayjs | null>(dayjs());
    const [txtCheckOutDate, setTxtCheckOutDate] = React.useState<Dayjs | null>(dayjs().add(2, 'day'));
    const [txtLocation, setTxtLocation] = React.useState<CityModel | null>();
    const [txtPeople, setTxtPeople] = React.useState({ label: '2 người', value: 2 });

    useEffect(() => {
        showLoading()
        Aos.init({duration: 2000})

        getCity().then((response) => {
            setCityData(response)
            setTxtLocation(response[0])
            closeLoading()
        })
    },[])

    const SearchRoom = () => {
        let checkInDate = dayjs(txtCheckInDate).format('DD/MM/YYYY')
        let checkOutDate = dayjs(txtCheckOutDate).format('DD/MM/YYYY')

        navigate('/booking-room',{
            state: {
                people: txtPeople.value,
                location: txtLocation?.cityCode,
                checkInDate: checkInDate,
                checkOutDate: checkOutDate
            }}
        )
    }

    return (   
    <div>
        <Header isHome={true} page='home'/>
        <div id="demo" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img id="background1" src="/media/core/background1.jpeg" height='800px' alt="Los Angeles" className="d-block" width='100%'></img>
                </div>
                <div className="carousel-item">
                    <img id="background2" src="/media/core/background2.jpeg" height='800px' alt="Chicago" className="d-block" width='100%'></img>
                </div>
                <div className="carousel-item">
                    <img id="background3" src="/media/core/background3.jpeg" height='800px' alt="New York" className="d-block" width='100%'></img>
                </div>
            </div>
            <div className="slide-text-welcome">
                <h1 data-aos="fade-up">Welcome to us</h1>
                <p data-aos="fade-up"> where the best options for you</p> 
            </div>
            <div className='card-booking'>
                <Box sx={{ width: '100%' }}>
                    <h5 style={{marginTop: '8px', marginBottom: '15px'}}>Đặt phòng ngay!</h5>
                    <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Ngày nhận phòng"
                                    value={txtCheckInDate}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        setTxtCheckInDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Ngày trả phòng"
                                    value={txtCheckOutDate}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        setTxtCheckOutDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {cityData && cityData?.length > 0 && 
                                <Autocomplete
                                    value={txtLocation}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={cityData}
                                    getOptionLabel={(option) => {
                                        return option.cityName;
                                      }}
                                    onChange={(event: any, newValue: CityModel | null) => {
                                        setTxtLocation(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth size="small" label="Địa điểm" />}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>

                            <Autocomplete
                                value={txtPeople}
                                disablePortal
                                id="combo-box-demo"
                                options={listGuest}
                                getOptionLabel={(option) => {
                                    return option.label;
                                    }}
                                onChange={(event: any, newValue: any | null) => {
                                    setTxtPeople(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth size="small" label="Số người" />}
                            />
                        </Grid>
                    </Grid>
                    <div className="div-btn-booking">
                        <Button className="btn-booking" variant="contained" onClick={SearchRoom}>
                            Tìm kiếm
                        </Button>
                    </div>
                </Box>
            </div>
            
            <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                <span className="carousel-control-next-icon"></span>
            </button>
        </div>
        <Container fixed>
            <Box sx={{ mt: 10, width: '100%' }}>
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={5}>
                        <h1 className="title" data-aos="fade-right">ABOUT US</h1>
                        <p data-aos="fade-up" style={{margin: 7, textAlign: 'justify'}}>
                            &emsp;Chào mừng đến với trang web đặt phòng khách sạn của chúng tôi! Chúng tôi rất vui khi có bạn ở đây 
                            và rất nóng lòng được giúp bạn lên kế hoạch cho chuyến đi tiếp theo. Trang web của chúng tôi được 
                            thiết kế để giúp bạn dễ dàng tìm và đặt khách sạn hoàn hảo phù hợp với nhu cầu của mình, 
                            bất kể bạn đang đi du lịch ở đâu hay ngân sách của bạn là bao nhiêu.
                        </p>
                        <p data-aos="fade-up" style={{margin: 7, textAlign: 'justify'}}>
                            &emsp;Với giao diện thân thiện với người dùng và các bộ lọc tìm kiếm toàn diện của chúng tôi, 
                            bạn có thể nhanh chóng thu hẹp các tùy chọn của mình và tìm các khách sạn đáp ứng các yêu cầu cụ thể của mình. 
                            Cho dù bạn đang tìm kiếm một khu nghỉ dưỡng sang trọng, một nhà nghỉ hợp túi tiền hay bất cứ thứ gì ở giữa, 
                            chúng tôi đều đáp ứng được cho bạn.
                        </p>
                        <Button style={{marginTop: '20px'}} className="btn-learn-more" onClick={() => navigate("/about-us")} variant="contained">
                            Tìm hiểu thêm
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Grid container rowSpacing={5}>
                            <Grid sx={{ mb: 5}} item xs={6} sm={6} md={6}>
                                <img className="image-about-us" data-aos="fade-down" src="/media/core/aboutUs1.png"></img>
                            </Grid>
                            <Grid item sx={{ mt: 5}} xs={6} sm={6} md={6}>
                                <img className="image-about-us" data-aos="fade-up" src="/media/core/aboutUs2.png"></img>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>

        {/* Our services */}
        <div style={{marginTop: '150px'}}>
            <h3 className="title" data-aos="fade-right">Our Services</h3>
            <Box sx={{width: '100%' }}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4}>
                    </Grid>
                    <Grid sx={{mr: '20px', ml: '20px' }} item xs={12} sm={12} md={4}>
                        Tại trang web đặt phòng của chúng tôi, chúng tôi cung cấp nhiều dịch vụ để đảm bảo rằng kế hoạch du lịch của bạn được 
                        liền mạch và không bị căng thẳng. Dưới đây là một số dịch vụ chính mà chúng tôi cung cấp:
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                    </Grid>
                </Grid>
            </Box>
            <div className="articles">
                <article>
                    <div className="article-wrapper">
                    <figure>
                        <img src="/media/images/booking.jpeg" alt="" />
                    </figure>
                    <div className="article-body">
                        <h2>Đặt phòng</h2>
                        <p>
                            Dịch vụ đặt phòng của chúng tôi giúp khách du lịch dễ dàng tìm và đặt phòng hoàn hảo cho nhu cầu của mình, 
                            bất kể họ đang đi du lịch ở đâu. Với giao diện thân thiện với người dùng và tính khả dụng trong thời gian thực, 
                            bạn có thể nhanh chóng tìm kiếm và đặt phòng lý tưởng của mình chỉ bằng vài cú nhấp chuột.
                        </p>
                        <a href="/service-us?tabId=booking" className="read-more">
                            Xem thêm 
                            <span className="sr-only">about this is some title</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </div>
                    </div>
                </article>
                <article>
                    <div className="article-wrapper">
                    <figure>
                        <img src="/media/images/rental.jpeg" alt="" />
                    </figure>
                    <div className="article-body">
                        <h2>Dịch vụ cho thuê</h2>
                        <p>
                            Cho thuê thiết bị du lịch là một cách thuận tiện để khách du lịch có được thiết bị họ cần mà không cần phải mua và tự đóng gói. 
                            Từ dụng cụ cắm trại đến thiết bị trẻ em, dịch vụ cho thuê cung cấp nhiều loại vật dụng cho mọi loại chuyến đi. 
                            Thuê cũng tiết kiệm tiền và giảm trọng lượng hành lý.
                        </p>
                        <a href="/service-us?tabId=rental" className="read-more">
                            Xem thêm 
                            <span className="sr-only">about this is some title</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </div>
                    </div>
                </article>
                <article>
                    <div className="article-wrapper">
                    <figure>
                        <img src="/media/images/payment.png" alt="" />
                    </figure>
                    <div className="article-body">
                        <h2>Thanh toán trực tuyến</h2>
                        <p>
                            Các dịch vụ thanh toán trực tuyến cung cấp một phương thức an toàn và thuận tiện để thanh toán hàng hóa và dịch vụ 
                            từ bất kỳ đâu có kết nối internet. Với các tính năng như giao dịch nhanh và thiết lập tài khoản dễ dàng, các dịch vụ này 
                            ngày càng trở nên phổ biến để đặt chỗ trực tuyến, thanh toán hóa đơn, v.v.
                        </p>
                        <a href="/service-us?tabId=payment" className="read-more">
                            Xem thêm
                            <span className="sr-only">about this is some title</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </div>
                    </div>
                </article>
            </div>
        </div>
        {/* end our services */}
         
        {/* the best places */}
        <div style={{marginTop: '150px', backgroundColor: 'rgb(243 243 243 / 61%)'}}>
            <div className="wrapper">
                <h2 className="title" data-aos="fade-right">Điểm đến đang thịnh hành</h2>
                <div className="gallery">
                    <div data-aos="fade-right" className="gallery__item gallery__item--1">
                        <a href="https://vnexpress.net/cam-nang-du-lich-mang-den-4619200.html" className="gallery__link" target="_blank">
                            <img src="/media/core/mangden.jpeg" className="gallery__image" />
                            <div className="gallery__overlay">
                                <span>Mang Den</span>
                            </div>
                        </a>
                    </div>
                    <div data-aos="fade-right" className="gallery__item gallery__item--2">
                        <a href="https://vnexpress.net/cam-nang-du-lich-dao-phu-quy-3775534.html" className="gallery__link" target="_blank">
                            <img src="/media/core/phuquy.jpeg" className="gallery__image" />
                            <div className="gallery__overlay">
                                <span>Phu Quy Island</span>
                            </div>
                        </a>
                    </div>
                    <div data-aos="fade-down" className="gallery__item gallery__item--3">
                        <a href="https://vnexpress.net/cam-nang-du-lich-ha-giang-4445788.html" className="gallery__link" target="_blank">
                            <img src="/media/core/hagiang.jpeg" className="gallery__image" />
                            <div className="gallery__overlay">
                                <span>Ha Giang</span>
                            </div>
                        </a>
                    </div>
                    <div data-aos="fade-left" className="gallery__item gallery__item--4">
                        <a href="https://vnexpress.net/cam-nang-du-lich-hue-4126937.html" className="gallery__link" target="_blank">
                            <img src="/media/core/hue.jpeg" className="gallery__image" />
                            <div className="gallery__overlay">
                                <span>Hue</span>
                            </div>
                        </a>
                    </div>
                    <div data-aos="fade-up" className="gallery__item gallery__item--5">
                        <a href="https://vnexpress.net/cam-nang-du-lich-ha-long-4457134.html" className="gallery__link" target="_blank">
                            <img src="/media/core/halong.jpeg" className="gallery__image" />
                            <div className="gallery__overlay">
                                <span>Ha Long Bay</span>
                            </div>
                        </a>
                    </div>
                    <div data-aos="fade-up" className="gallery__item gallery__item--6">
                        <a href="https://vnexpress.net/cam-nang-du-lich-da-lat-4455699.html" className="gallery__link" target="_blank">
                            <img src="/media/core/dalat.jpeg" className="gallery__image" />
                            <div className="gallery__overlay">
                                <span>Da Lat</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        {/* end the best places */}

        {/* the payment channels */}
        <div style={{marginTop: '150px'}} className="container">
            <h2 className="title" data-aos="fade-right">Kênh thanh toán</h2>
            <div className="wrapper-payment">
                <div className="slider">
                    <div className="slide-track">
                    <div className="slide">
                            <img src="/media/images/vietQr.png" />
                        </div>
                        <div className="slide">
                            <img src="/media/images/mpgs.png" />
                        </div>
                        <div className="slide">
                            <img src="/media/images/galaxypay.png" />
                        </div>
                        <div className="slide">
                            <img src="/media/images/jcb.webp" />
                        </div>
                        <div className="slide">
                            <img src="/media/images/momo.jpeg" />
                        </div>
                        
                        <div className="slide">
                            <img src="/media/images/napas.jpeg" />
                        </div>
                        
                        <div className="slide">
                            <img src="/media/images/viettel.jpeg" />
                        </div>
                        
                        <div className="slide">
                            <img src="/media/images/zalo.webp" />
                        </div>
                        
                        <div className="slide">
                            <img src="/media/images/visa.jpeg" />
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        {/* end the payment channels */}

        {/* contact us */}
        <Contact/>
        {/* contact us */}
        <Footer/>
    </div>
  );
}

export default Home;

