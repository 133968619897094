
const showLoading = (): void =>
{
    const loading: any = document.getElementById("loading");
    if (loading)
    {
        loading.style.display = "block";
    }
};

const closeLoading = (): void =>
{
    const loading: any = document.getElementById("loading");
    if (loading)
    {
        loading.style.display = "none";
    }
};


const LoadingScreen = () =>
{
    return (
        <div id="loading">
            <div id="overlay"></div>
            <div id="div-loading">
                <div>
                     <div className="loadingio-spinner-double-ring-nxoju77pb0c"><div className="ldio-lipn2c8m2x">
                        <div></div>
                        <div></div>
                        <div><div></div></div>
                        <div><div></div></div>
                        </div></div>
                </div>
                <div id='txt-loading'>
                    Vui lòng chờ..
                </div>
            </div>
        </div>
    );
};

export
{
    LoadingScreen,
    showLoading,
    closeLoading
};