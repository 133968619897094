import './css/product-image-slider.scss'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import {Navigation, Thumbs, type Swiper as SwiperCore, Pagination } from 'swiper'
import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import {isMobile} from 'react-device-detect';
import React from 'react'

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                <CloseIcon />
            </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const ProductImagesSlider = (props: { images: any[] }) => {
    const [activeThumb, setActiveThumb] =  useState<SwiperCore>();

    return <>
        <Swiper
            loop={true}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation, Thumbs]}
            grabCursor={true}
            thumbs={{ swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null }}
            className='product-images-slider'
        >
            {
                props.images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <img style={{borderRadius: '8px'}} height="90%" src={item.imageUrl} alt="product images" />
                    </SwiperSlide>
                ))
            }
        </Swiper>
        <Swiper
            onSwiper={setActiveThumb}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            modules={[Navigation, Thumbs]}
            className='product-images-slider-thumbs'
        >
            {
                props.images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="product-images-slider-thumbs-wrapper">
                            <img style={{borderRadius: '8px'}} height="100%" src={item.imageUrl} alt="product images" />
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </>
}

const RentalSlider = (props: {slides: any[]}) => {
    const [openTourstPlace, setOpenTourstPlace] = React.useState(false);
    const [touristPlaceData, setTouristPlaceData] = React.useState<any>();

    const handleClickOpenTourstPlace = (data: any) => {
        setTouristPlaceData(data);
        setOpenTourstPlace(true);
    };

    const handleCloseTourstPlace = () => {
        setOpenTourstPlace(false);
    };

    return <>
        <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={ isMobile ? 1 : 3}
            navigation
            className='rental-slider'
            >
            {props.slides.map((slide) => (
                <SwiperSlide key={slide.touristPlaceID}>
                <div className='card-tourist-place'>
                    <div>
                        <img className='card-tourist-place-image' src={slide.touristPlaceImages[0].imageUrl} alt="product images"/>
                        <div style={{padding: '5px'}}>
                            <b>{slide.touristPlaceName}</b>
                        </div>
                        <div style={{ textAlign:'left' }}>
                            <span style={{ fontSize: '13px'}}><b>Địa chỉ:</b> {slide.address}</span>
                        </div>
                    </div>
                    <div>
                        <Button size="small" 
                            variant="outlined" 
                            sx={{textTransform: 'none'}}
                            onClick={() => handleClickOpenTourstPlace(slide)}>
                            Xem thêm
                        </Button>
                    </div>
                </div>
                </SwiperSlide>
            ))}
        </Swiper>
        <Dialog
            open={openTourstPlace}
            onClose={handleCloseTourstPlace}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {touristPlaceData &&
                <>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseTourstPlace}>
                        <b>{touristPlaceData.touristPlaceName}</b>
                    </BootstrapDialogTitle>
                    <DialogContent className='card-tourist-place-popup-content'>
                        <DialogContentText id="alert-dialog-description">
                            <img className='card-tourist-place-popup-content-image' src={touristPlaceData.touristPlaceImages[0].imageUrl}></img>
                            <div style={{marginTop: '25px'}}>
                                <span><b>Số điện thoại:</b> {touristPlaceData.phoneNumber}</span>
                            </div>
                            <div style={{marginTop: '8px'}}>
                                <span><b>Địa chỉ:</b> {touristPlaceData.address}</span>
                            </div>
                            <div style={{marginTop: '20px'}}>
                                <span>{touristPlaceData.content}</span>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </>
            }
        </Dialog>
    </>
}

ProductImagesSlider.propTypes = {
    images: PropTypes.array.isRequired
}

export {ProductImagesSlider, RentalSlider} 
