import { useEffect, useRef } from 'react';
import React from 'react';
import './css/Rental.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/swiper-bundle.min.css';
import Header from '../base/Header';
import Footer from "../base/Footer";
import Aos from 'aos';
import { 
    Box, 
    Button, 
    Container, 
    Dialog, 
    DialogActions,
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControl, 
    FormControlLabel, 
    FormLabel, 
    Grid, 
    Radio, 
    RadioGroup, 
    TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductImagesSlider } from '../room/ProductImagesSlider';
import { queryServices, saveRental } from './core/service';
import { ServiceModel, } from './core/models';
import { AuthModel } from '../auth/core/models';
import { getAuth } from '../auth/core/authHelpers';
import { useAppSelector } from '../..';
import { closeLoading, showLoading } from '../loading/LoadingScreen';
import { PaymentOrder } from '../payment/PaymentOrder';
import { IsEmpty } from '../../core/utilities/functionBase';

type RefHandler = {
    handleClickOpen: (
        user: number,
        orderID: number,
        branchID: number,
        orderType: string,
        fullName: string,
        email: string,
        phoneNumber: string,
        orderName: string,
        dateCheckIn: string,
        dateCheckOut: string,
        dateCheckInDisplay: string,
        dateCheckOutDisplay: string,
        description: string,
        count: number,
        totalAmount: number,
        paymentMethod: string
    ) => void
}

function ServiceDetail() {
    const navigator = useNavigate()
    const params: any = useParams();
    const childRef = useRef() as React.MutableRefObject<RefHandler>;
    const authData: AuthModel | undefined = getAuth();
    const userData = useAppSelector((state) => state.user);

    const [serviceData, setServiceData] = React.useState<ServiceModel>();
    const [txtFullName, setTxtFullname] = React.useState("");
    const [txtPhoneNumber, setTxtPhoneNumber] = React.useState("");
    const [txtEmail, setTxtEmail] = React.useState("");
    const [txtFromDate, setTxtFromDate] = React.useState<Dayjs | null>(null);
    const [txtToDate, setTxtToDate] = React.useState<Dayjs | null>(null);
    const [txtCount, setTxtCount] = React.useState("");
    const [txtDescription, setTxtDescription] = React.useState("");
    const [optPayment, setOptPayment] = React.useState('online');

    const [isRental, setRental] = React.useState(false);
    const [isErrorRental, setErrorRental] = React.useState(false);
    const [txtErrorBooking, setTxtErrorRental] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOptPayment((event.target as HTMLInputElement).value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigator("/login")
    };

    useEffect(() => {
        showLoading()
        Aos.init({duration: 2000})
        
        if(params){
            queryServices(params.serviceID).then((response) => {
                setServiceData(response)
                closeLoading()
            })
        }

    }, [])

    const submitBooking = () => {

        let toDateDisplay = dayjs(txtToDate).format('DD/MM/YYYY')
        let fromDateDisplay = dayjs(txtFromDate).format('DD/MM/YYYY')
        let toDate = dayjs(txtToDate).format('YYYYMMDD')
        let fromDate = dayjs(txtFromDate).format('YYYYMMDD')
       
        if(!authData || IsEmpty(userData)){
            handleClickOpen()
            return;
        }


        if(!txtFullName || !txtPhoneNumber || !txtEmail || !fromDate || !toDate){
            setErrorRental(true)
            setTxtErrorRental("Vui lòng điền đầy đủ thông tin")
            return;
        }

        if(fromDate > toDate){
            setErrorRental(true)
            setTxtErrorRental("Vui lòng kiểm tra ngày nhận và ngày trả")
            return;
        }

        var Difference_In_Time = dayjs(txtToDate).toDate().getTime() - dayjs(txtFromDate).toDate().getTime();
        var totalDays = Difference_In_Time / (1000 * 3600 * 24);
        var count = Number(txtCount);

        if(serviceData){
            childRef.current.handleClickOpen(
                userData.id,
                serviceData.serviceID,
                serviceData.branchID,
                "SERVICE",
                txtFullName, 
                txtPhoneNumber, 
                txtEmail, 
                serviceData.serviceName, 
                fromDate,
                toDate, 
                fromDateDisplay,
                toDateDisplay,
                txtDescription, 
                count,
                serviceData.price * totalDays * count, 
                optPayment
            )
        }

        setErrorRental(false)
        setTxtErrorRental("")
    }

    return (
      <div>
        <Header isHome={false} page='rental'/>
            <div style={{height: '68px', backgroundColor: 'rgb(41, 50, 60)'}}></div>
            <Container style={{marginTop: '30px', marginBottom: '100px'}}>
                <div style={{
                    textAlign: 'left',
                    borderBottom: '2px solid',
                    borderBottomColor: '#a19590'
                }}>
                    <b>Dịch vụ</b>
                </div>
                <Box sx={{ flexGrow: 1, marginTop: '50px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <div style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#fff',
                                            padding: '10px'
                                            }}>
                                            { serviceData &&
                                                <ProductImagesSlider images={serviceData?.serviceImages} />
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div style={{textAlign: 'left', marginTop: '20px'}}>
                                        <div style={{fontSize: '24px', color: '#09a9b2'}}><b>{serviceData?.serviceName}</b></div>
                                        <div style={{display: 'flex', fontSize: '13px', color: '#b46806'}}>
                                            <span>{serviceData?.cityName}</span>
                                        </div>
                                        <div style={{display: 'flex', marginTop: '20px', fontSize: '15px'}}>
                                            <span><b>Số điện thoại: </b>{serviceData?.phoneNumber}</span>
                                        </div>
                                        <div style={{display: 'flex', fontSize: '15px' }}>
                                            <span> <b>Địa chỉ: </b>{serviceData?.address}</span>
                                        </div>
                                        <div style={{display: 'flex', marginTop: '20px', fontSize: '24px', color: 'rgb(242 141 10)'}}>
                                            <b>{serviceData?.displayPrice} VND</b>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div style={{textAlign: 'left', marginTop: '20px'}}>
                                        <span style={{ whiteSpace: "pre-line" }}>{serviceData?.content}</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div style={{backgroundColor: 'white', padding: '20px 0', borderRadius: '8px'}}>
                            <div style={{ padding: '20px', textAlign: 'center'}}>
                                <b>Đặt ngay!</b>
                            </div>
                            <div hidden={!isErrorRental} style={{ marginBottom: '5px', color: 'red' }}>{txtErrorBooking}</div>
                            <div style={{ borderRadius: '8px'}}>
                                <div style={{width: '90%', marginRight: '5%', marginLeft: '5%'}}>
                                    <TextField 
                                        id="txtFullName" 
                                        fullWidth 
                                        label="Họ và tên"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setTxtFullname(event.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%', display: 'flex'}}>
                                    <TextField  style={{marginRight: '10px'}} 
                                        id="txtPhoneNumber" 
                                        fullWidth 
                                        label="Số điện thoại" 
                                        inputProps={{ maxLength: 10 }}
                                        variant="standard"
                                        value={txtPhoneNumber}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setTxtPhoneNumber(event.target.value.replace(/\D/g, ''));
                                        }}
                                    />
                                    <TextField style={{marginLeft: '10px'}}
                                        type="email"
                                        id="txtEmail" 
                                        fullWidth 
                                        label="Email" 
                                        variant="standard"
                                        inputProps={{ maxLength: 100 }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setTxtEmail(event.target.value);
                                        }}
                                    />
                                </div>
                               
                                <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%', display: 'flex'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Từ ngày"
                                            value={txtFromDate}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={(newValue) => {
                                                setTxtFromDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField fullWidth style={{marginRight: '10px'}} variant="standard" {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Đến ngày"
                                            value={txtToDate}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={(newValue) => {
                                                setTxtToDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField fullWidth style={{marginLeft: '10px'}} variant="standard" {...params} />}
                                            />
                                    </LocalizationProvider>         
                                </div>
                                <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%'}}>
                                    <TextField 
                                        type="number"
                                        id="txtCount" 
                                        fullWidth 
                                        label="Số lượng"
                                        variant="standard"
                                        inputProps={{ maxLength: 2}}
                                        onInput = {(e: React.ChangeEvent<HTMLInputElement>) =>{
                                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                                        }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setTxtCount(event.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{width: '90%', marginTop: '20px', marginRight: '5%', marginLeft: '5%'}}>
                                    <TextField
                                        fullWidth
                                        id="txtDescription"
                                        label="Nội dung"
                                        multiline
                                        rows={4}
                                        variant="standard"
                                        inputProps={{ maxLength: 500 }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setTxtDescription(event.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{marginTop: '40px', marginLeft: '18px'}}>
                                    <FormControl>
                                        <FormLabel style={{color: 'rgb(242, 141, 10)'}} id="demo-row-radio-buttons-group-label">Phương thức thanh toán</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={optPayment}
                                            onChange={handlePaymentChange}
                                        >
                                            <FormControlLabel value="online" control={<Radio />} label="Thanh toán ngay" />
                                            <FormControlLabel value="offline" control={<Radio />} label="Thanh toán sau" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <Button style={{marginTop: '30px'}} 
                                    className="btn-booking" 
                                    variant="contained"
                                    onClick={submitBooking}
                                    disabled={isRental}
                                >
                                    {isRental && <i className="fa fa-spinner fa-spin" style={{marginRight: '5px'}}></i>}
                                        Đặt dịch vụ
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Bạn chưa đăng nhập?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Vui lòng đăng nhập để thực hiện thuê thiết bị/ vật dụng.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>Xác nhận</Button>
                    </DialogActions>
                </Dialog>
                <PaymentOrder ref={childRef}></PaymentOrder>
            </Container>
        <Footer/>
      </div>
    );
}

export default ServiceDetail;