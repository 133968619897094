import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Header from '../base/Header';
import Footer from "../base/Footer";
import './css/ServiceUs.css';
import Aos from 'aos';

function ServiceUs() {
    const queryParameters = new URLSearchParams(window.location.search)
    const tabId = queryParameters.get("tabId") || '';

    useEffect(() => {
        Aos.init({duration: 2000});
        const element = document.getElementById(tabId);
        if (element) {
        // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div>
            <Header isHome={true} page='service-us'/>
            <div id="demo" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="/media/core/background1.jpeg" height='800px' alt="Los Angeles" className="d-block" width='100%'></img>
                    </div>
                    <div className="carousel-item">
                        <img src="/media/core/background2.jpeg" height='800px' alt="Chicago" className="d-block" width='100%'></img>
                    </div>
                    <div className="carousel-item">
                        <img src="/media/core/background3.jpeg" height='800px' alt="New York" className="d-block" width='100%'></img>
                    </div>
                </div>
                <div className="slide-text-welcome">
                    <h1 data-aos="fade-up">Services</h1>
                    <p data-aos="fade-up"> where the best options for you</p> 
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>
            <div id="booking" className='container' style={{paddingTop: '100px'}}>
                <div>
                    <h2>Đặt phòng trực tuyến</h2>
                    <div style={{borderBottom: '5px solid rgb(53 202 217)', textAlign: 'left', fontSize: '20px', width: '70px', margin: 'auto', borderRadius: '5px'}}></div>
                </div>
                <Box sx={{ flexGrow: 1, marginTop: '20px' }}>
                    <Grid className='serive-gird-item-content' container spacing={5}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={3}>
                            <img style={{borderRadius: '16px'}} src="/media/core/serviceUs1.jpeg" alt='' width="90%" height="180px"></img>
                        </Grid>
                        <Grid data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={3}>
                            <img style={{borderRadius: '16px'}}  src="/media/core/serviceUs2.avif" alt='' width="90%" height="180px"></img>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid data-aos="fade-up" item xs={12} md={8}>
                            &emsp;Dịch vụ đặt phòng là một cách thuận tiện để du khách đặt phòng khách sạn trước. Chỉ với một vài cú nhấp chuột, 
                            khách hàng có thể duyệt các phòng có sẵn, so sánh giá cả và chọn tùy chọn tốt nhất phù hợp với ngân sách và sở thích của họ. 
                            Dịch vụ này không chỉ tiết kiệm thời gian, công sức mà còn mang đến sự minh bạch, linh hoạt cho khách hàng.<br/>

                            &emsp;Dịch vụ đặt phòng thường cung cấp nhiều lựa chọn, từ phòng bình dân đến phòng sang trọng, với nhiều tiện nghi như Wi-Fi miễn phí,
                             bữa sáng và chỗ đậu xe. Khách hàng cũng có thể lựa chọn các loại phòng khác nhau như đơn, đôi hoặc dãy phòng.<br/>

                            &emsp;Một trong những lợi ích chính của dịch vụ đặt phòng là khả năng đọc các nhận xét từ những khách du lịch khác đã ở cùng khách sạn trước đó. 
                            Điều này có thể giúp khách hàng đưa ra quyết định sáng suốt và tránh mọi bất ngờ khó chịu trong thời gian lưu trú. Ngoài ra, nhiều 
                            dịch vụ đặt phòng cung cấp các chương trình phần thưởng, cho phép khách hàng tích điểm và nhận chiết khấu cho các đặt phòng trong tương lai.<br/>

                            &emsp;Nhìn chung, dịch vụ đặt phòng đã cách mạng hóa cách mọi người đặt phòng khách sạn. Bằng cách cung cấp một quy trình đơn giản và hiệu quả, 
                            khách hàng có thể dễ dàng đặt chỗ ở lý tưởng và tập trung tận hưởng chuyến đi của mình.<br/>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                    </Grid>
                </Box>
            </div>
            <div id="rental" className='container' style={{paddingTop: '100px'}}>
                <div>
                    <h2>Thuê dịch vụ trực tuyến</h2>
                    <div style={{borderBottom: '5px solid rgb(53 202 217)', textAlign: 'left', fontSize: '20px', width: '70px', margin: 'auto', borderRadius: '5px'}}></div>
                </div>
                <Box sx={{ flexGrow: 1, marginTop: '20px' }}>
                    <Grid className='serive-gird-item-content' container spacing={5}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={3}>
                            <img style={{borderRadius: '16px'}}  src="/media/core/serviceUs3.jpeg" alt='' width="90%" height="180px"></img>
                        </Grid>
                        <Grid data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={3}>
                            <img style={{borderRadius: '16px'}}  src="/media/core/serviceUs4.jpeg" alt='' width="90%" height="180px"></img>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid data-aos="fade-up" item xs={12} md={8}>
                            &emsp;Dịch vụ cho thuê thiết bị du lịch ngày càng trở nên phổ biến trong những năm gần đây, khi ngày càng có nhiều người 
                            lựa chọn những chuyến du lịch nhẹ nhàng và tránh phiền phức khi mang theo các thiết bị và phụ kiện cồng kềnh. 
                            Từ dụng cụ cắm trại đến thiết bị đi bộ đường dài, máy ảnh đến thiết bị GPS, các công ty cho thuê dụng cụ du lịch cung cấp 
                            nhiều lựa chọn cho khách du lịch muốn tiết kiệm không gian và tiền bạc.<br/>
                            
                            &emsp;Một trong những lợi ích chính của việc cho thuê thiết bị du lịch là tiết kiệm chi phí. Mua thiết bị và dụng cụ chất 
                            lượng cao có thể tốn kém, đặc biệt đối với những món đồ chỉ được sử dụng vài lần trong năm. Với các dịch vụ cho thuê, 
                            khách du lịch có thể tiếp cận với các thiết bị hàng đầu mà không phải bỏ ra một số tiền đáng kể.<br/>

                            &emsp;Một ưu điểm khác của dịch vụ cho thuê thiết bị du lịch là sự tiện lợi. Thay vì phải đóng gói và mang theo các thiết bị cồng kềnh, 
                            khách du lịch có thể chỉ cần thuê những thứ họ cần tại điểm đến và trả lại khi sử dụng xong. Điều này có thể đặc biệt hữu ích 
                            cho các hoạt động ngoài trời như cắm trại và đi bộ đường dài, nơi thiết bị có thể chiếm nhiều không gian và tăng thêm trọng lượng cho hành lý.<br/>

                            &emsp;Hơn nữa, cho thuê thiết bị du lịch có thể là cơ hội để dùng thử thiết bị mới trước khi cam kết mua hàng. Điều này đặc biệt hữu ích đối với 
                            các mặt hàng như máy ảnh hoặc máy bay không người lái, những mặt hàng mà chi phí mua hàng có thể là đáng kể. Bằng cách thuê và 
                            dùng thử các mẫu khác nhau, khách du lịch có thể đưa ra quyết định sáng suốt về thiết bị nào phù hợp nhất với nhu cầu của họ.<br/>

                            &emsp;Nhìn chung, dịch vụ cho thuê thiết bị du lịch là một lựa chọn tuyệt vời cho bất kỳ ai muốn đi du lịch nhẹ nhàng, 
                            tiết kiệm tiền và thử nghiệm thiết bị mới. Với nhiều dịch vụ cho thuê có sẵn cả trực tuyến và trực tiếp, khách du lịch có nhiều 
                            lựa chọn hơn bao giờ hết khi cần tìm thiết bị phù hợp cho chuyến đi của mình.
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                    </Grid>
                </Box>
            </div>
            <div id="payment" className='container' style={{paddingTop: '100px', paddingBottom: '70px'}}>
                <div>
                    <h2>Thanh toán trực tuyến</h2>
                    <div style={{borderBottom: '5px solid rgb(53 202 217)', textAlign: 'left', fontSize: '20px', width: '70px', margin: 'auto', borderRadius: '5px'}}></div>
                </div>
                <Box sx={{ flexGrow: 1, marginTop: '20px' }}>
                    <Grid className='serive-gird-item-content' container spacing={5}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={3}>
                            <img style={{borderRadius: '16px'}}  src="/media/core/serviceUs5.webp" alt='' width="90%" height="180px"></img>
                        </Grid>
                        <Grid data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={3}>
                            <img style={{borderRadius: '16px'}}  src="/media/core/serviceUs6.jpeg" alt='' width="90%" height="180px"></img>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid data-aos="fade-up" item xs={12} md={8}>
                            &emsp;Dịch vụ thanh toán trực tuyến là một phương thức thanh toán kỹ thuật số cho hàng hóa hoặc dịch vụ sử dụng internet. 
                            Nó ngày càng trở nên phổ biến trong những năm gần đây, với sự phát triển của thương mại điện tử và nhu cầu ngày càng tăng đối 
                            với các giao dịch thanh toán nhanh chóng và an toàn. Dịch vụ thanh toán trực tuyến cung cấp một cách thuận tiện và an toàn 
                            cho khách hàng để mua hàng mà không cần tiền mặt hoặc thẻ tín dụng thực tế. <br/>

                            &emsp;Các dịch vụ thanh toán trực tuyến thường sử dụng mã hóa và các biện pháp bảo mật khác để bảo vệ thông tin cá nhân và 
                            tài chính của khách hàng. Điều này đảm bảo rằng các giao dịch của họ được an toàn và bảo mật. Một số dịch vụ thanh toán trực 
                            tuyến phổ biến nhất bao gồm Napas, MoMo và ZaloPay.. cho phép khách hàng thanh toán bằng tài khoản ngân hàng, thẻ tín dụng 
                            hoặc thẻ ghi nợ hoặc thậm chí thông qua thiết bị di động.<br/>

                            &emsp;Ngoài sự thuận tiện và an toàn, các dịch vụ thanh toán trực tuyến còn mang đến cho doanh nghiệp khả năng mở rộng cơ sở 
                            khách hàng và tiếp cận các thị trường mới. Bằng cách chấp nhận thanh toán trực tuyến, doanh nghiệp có thể bán sản phẩm hoặc 
                            dịch vụ của mình cho khách hàng trên toàn thế giới, 24/7.<br/>

                            &emsp;Nhìn chung, các dịch vụ thanh toán trực tuyến đã cách mạng hóa cách mọi người thực hiện giao dịch, giúp giao dịch nhanh 
                            hơn, an toàn hơn và thuận tiện hơn cho khách hàng cũng như doanh nghiệp.

                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                    </Grid>
                </Box>
            </div>
            <Footer/>
        </div>
    );
}

export default ServiceUs;