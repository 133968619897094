import { useEffect } from 'react';
import Header from '../base/Header';
import Footer from "../base/Footer";
import { 
    Box,
    Container, 
    Grid} from '@mui/material';
import React from 'react';

function ResultPage() {
   
    const queryParameters = new URLSearchParams(window.location.search)
    const result = queryParameters.get("result")
    const orderNumber = queryParameters.get("orderNumber")

    const [logo, setLogo] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [color, setColor] = React.useState("");

    useEffect(() => {
        switch(result){
            case "failure":
                setLogo("fail")
                setMessage("Thất bại")
                setOrder(orderNumber || '')
                setColor("#F93E2E")
                break;
            case "success":
                setLogo("success")
                setMessage("Thành công")
                setOrder(orderNumber || '')
                setColor("rgb(82 196 5)")
                break;
        }
    })
    

    return (
        <div>
            <Header isHome={false} page=''/>
            <div style={{height: '68px', backgroundColor: 'rgb(41, 50, 60)'}}></div>
            <Container style={{marginTop: '20px', marginBottom: '100px', margin: '0 auto'}}>
                <Box sx={{ flexGrow: 1, marginTop: '50px'}}>
                    <Grid container>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={6} style={{ backgroundColor: 'white', borderRadius: '8px', padding: '30px' }}>
                            <img src={`/media/images/${logo}.png`} height='100px'></img>
                            <div style={{marginTop: '20px', color: `${color}`, fontSize: '20px'}}>
                                <b>{message}</b>
                            </div>
                            <div style={{marginTop: '20px'}}>
                                <span>Mã đơn hàng: </span>
                                <b>{order}</b>
                            </div>
                        </Grid>  
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
}

export default ResultPage;