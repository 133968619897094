import {useState } from 'react';
import './css/Auth.css'
import { Link, useNavigate } from 'react-router-dom';
import { getUserByToken, register } from './core/service';
import { saveAuth } from './core/authHelpers';
import { IsEmpty } from '../../core/utilities/functionBase';
import { useDispatch } from 'react-redux';
import { IStoreUser, setUser } from '../../core/controllers/ReduxStore';

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [txtFirtName, setTxtFirtName] = useState('')
  const [txtLastName, setTxtLastName] = useState('')
  const [txtEmail, setEmail] = useState('')
  const [txtPhoneNumber, setTxtPhoneNumber] = useState('')
  const [txtPassword, setTxtPassword] = useState('')
  const [txtConfirmPassword, setTxtConfirmPassword] = useState('')
  const [isLoginClick, setIsSignUpClick] = useState(false)
  const [isError, setIsError] = useState(false)
  const [txtError, setTxtError] = useState('')

  const onChangeFirtName = (event: any) => {
    setIsError(false)
    setTxtError('')
    setTxtFirtName(event.target.value)
  }

  const onChangeLastName = (event: any) => {
    setIsError(false)
    setTxtError('')
    setTxtLastName(event.target.value)
  }

  const onChangeEmail = (event: any) => {
    setIsError(false)
    setTxtError('')
    setEmail(event.target.value)
  }

  const onChangePhoneNumber = (event: any) => {
    setIsError(false)
    setTxtError('')
    setTxtPhoneNumber(event.target.value)
  }

  const onChangePassword = (event: any) => {
    setTxtPassword(event.target.value)
    if(event.target.value !== txtConfirmPassword){
      setIsError(true)
      setTxtError('Error! Confirm Password Not Match')
    }else{
      setIsError(false)
      setTxtError('')
    }
    return;
  }

  const onChangeConfirmPassword = (event: any) => {
    setTxtConfirmPassword(event.target.value)
    if(txtPassword !== event.target.value){
      setIsError(true)
      setTxtError('Error! Confirm Password Not Match')
     
    }else{
      setIsError(false)
      setTxtError('')
    }
    return;
  }

  const onClickSunmit = async () => {
    setIsSignUpClick(true)
    
    if(!txtFirtName || !txtLastName || !txtEmail || !txtPhoneNumber || !txtPassword || !txtConfirmPassword){
      setIsSignUpClick(false)
      return;
    }

    const auth = await register(txtFirtName, txtLastName, txtEmail, txtPhoneNumber, txtPassword, txtConfirmPassword)
    if(!IsEmpty(auth)){
      const user = await getUserByToken(auth.apiToken)
      if(!IsEmpty(user)){
        let storeUser: IStoreUser = {
          id: user.id,
          userName: user.userName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          firstName: user.firstName,
          lastName: user.lastName
        }
  
        dispatch(setUser(storeUser))
        saveAuth(auth)
        navigate("/home");
      }
    }
  
    setIsError(true)
    setTxtError('Error! The registration detail is incorrect')
    setIsSignUpClick(false)
  }

  return (
    <div className='container-auth'>
      <div className="center-auth">
        <div>
          <h1>Sign Up</h1>
          <form className='divForm'>
            <div className='row'>
              <div style={{margin: '20px 10px 0px 13px'}} className="col txt_field-auth">
                <input style={{marginLeft: '-25px'}} type="text" value={txtFirtName} onChange={onChangeFirtName} required></input>
                <span></span>
                <label>Họ</label>
              </div>
              <div style={{margin: '20px 13px 0px 10px'}} className="col txt_field-auth">
                <input style={{marginLeft: '-25px'}} type="text" value={txtLastName} onChange={onChangeLastName} required></input>
                  <span></span>
                  <label>Tên</label>
              </div>
            </div>
            <div className="txt_field-auth">
              <input type="email" value={txtEmail} onChange={onChangeEmail} required></input>
              <span></span>
              <label>Email</label>
            </div>
            <div className="txt_field-auth">
              <input type="number" value={txtPhoneNumber} onChange={onChangePhoneNumber} required></input>
              <span></span>
              <label>Số điện thoại</label>
            </div>
            <div className="txt_field-auth">
              <input type="password" value={txtPassword} onChange={onChangePassword} required></input>
              <span></span>
              <label>Mật khẩu</label>
            </div>
            <div className="txt_field-auth">
              <input type="password" value={txtConfirmPassword} onChange={onChangeConfirmPassword} required></input>
              <span></span>
              <label>Xác nhận mật khẩu</label>
            </div>
            <p style={{color:'red'}} hidden={!isError}>{txtError}</p>
            {isLoginClick && <button className='btn-auth' disabled><i className="fa fa-spinner fa-spin"></i> Đăng ký</button>}
            {!isLoginClick && <button onClick={onClickSunmit} className='btn-auth' disabled={isError}>Đăng ký</button>}
            <div className="signup_link-auth">
              Already a member? <Link to="/login">Login</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
