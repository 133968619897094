import { ServiceOption } from '../../../core/options/serviceOption';
import { Post } from '../../../core/services/serviceBase';
import { OrderModel, PaymentOrderResponse } from './models';

const API_URL = process.env.REACT_APP_API_URL
export const SAVE_ORDER_URL = `${API_URL}/Order/Save`
export const QUERY_ORDER_URL = `${API_URL}/Order/Query`
export const PAYMENT_URL = `${API_URL}/Order/Payment`


export async function saveOrder(
    orderReferenceID: number,
    orderReferenceName: string,
    branchID: number,
    orderType: string,
    orderTotalAmount: string,
    fullName: string,
    phoneNumber: string,
    email: string,
    dateCheckIn: number,
    dateCheckOut: number,
    description: string,
    count: number,
    paymentMethod: string,
    userIDCreate: number) {

    const response = await Post(SAVE_ORDER_URL, { 
        orderReferenceID,
        orderReferenceName,
        branchID,
        orderType, 
        orderTotalAmount, 
        fullName, 
        phoneNumber,
        email, 
        dateCheckIn, 
        dateCheckOut, 
        description, 
        count, 
        paymentMethod, 
        userIDCreate 
    })

    let orderPayment: PaymentOrderResponse;
    
    if(response?.responseCode === ServiceOption.Success){
        orderPayment = response.responseData;
        return orderPayment;
    }
    
    return null;
}

export async function queryOrder(orderNumber: string, userID: number) {
    const response = await Post(QUERY_ORDER_URL, { orderNumber, userID })
    let orderData: OrderModel;
    
    if(response?.responseCode === ServiceOption.Success){
        orderData = response.responseData;
        return orderData;
    }
    
    return null;
}

export async function paymentOrder(orderNumber: string, userID: number) {
         
    const response = await Post(PAYMENT_URL, { orderNumber, userID })
    let payment = {} as PaymentOrderResponse;
    
    if(response?.responseCode === ServiceOption.Success){
        payment = response.responseData;
    }
    
    return payment;
}