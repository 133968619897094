import { ServiceOption } from '../../../core/options/serviceOption';
import { Post } from '../../../core/services/serviceBase';
import { ServiceModel, RentalResponse } from './models';

const API_URL = process.env.REACT_APP_API_URL
export const GET_SERVICE_URL = `${API_URL}/Service/GetServices`
export const SEARCH_URL = `${API_URL}/Service/Search`
export const QUERY_URL = `${API_URL}/Service/Query`
export const TOP_EQUIPMENT_URL = `${API_URL}/Service/TopSearch`
export const SAVE_RENTAL_URL = `${API_URL}/Rental/Save`

export async function getServices() {
    const response = await Post(GET_SERVICE_URL, {})
    let responseData = {} as Array<ServiceModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function searchServices(city: string, serviceID: number) {
    const response = await Post(SEARCH_URL, {city, serviceID})
    let responseData = {} as Array<ServiceModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function topSearchServices() {
    const response = await Post(TOP_EQUIPMENT_URL, {})
    let responseData = {} as Array<ServiceModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function queryServices(serviceID: string) {
    const response = await Post(QUERY_URL, { serviceID })
    let responseData = {} as ServiceModel;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function saveRental(
    serviceID: number,
    amount: number,
    fullName: string, 
    phoneNumber: string, 
    email: string, 
    fromDate: string, 
    toDate: string, 
    description: string, 
    count: string, 
    paymentMethod: string, 
    userIDCreate: number) {

    const response = await Post(SAVE_RENTAL_URL, { 
        serviceID,
        amount,
        fullName,
        phoneNumber,
        email,
        fromDate,
        toDate,
        description,
        count,
        paymentMethod,
        userIDCreate
    })

    let responseData = {} as RentalResponse;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}