import { useEffect } from 'react';
import './App.css';
import Home from './app/home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './app/auth/Login';
import SignUp from './app/auth/SignUp';
import { IsEmpty } from './core/utilities/functionBase';
import { IStoreUser, setUser } from './core/controllers/ReduxStore';
import { useDispatch } from 'react-redux';
import { getUserByToken } from './app/auth/core/service';
import { getAuth } from './app/auth/core/authHelpers';
import { AuthModel } from './app/auth/core/models';
import { Logout } from './app/auth/Logout';
import AboutUs from './app/about/AboutUs';
import Room from './app/room/Room';
import RoomDetail from './app/room/RoomDetail';
import ServiceUs from './app/service/ServiceUs';
import Rental from './app/rental/Rental';
import ServiceDetail from './app/rental/RentalDetail';
import { LoadingScreen } from './app/loading/LoadingScreen';
import Payment from './app/payment/Payment';
import ResultPage from './app/payment/ResultPage';
import AttractionPlacePage from './app/attraction-place/AttractionPlacePage';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.performance) {
      let authData: AuthModel | undefined = getAuth();
      if (authData && authData.apiToken) {
        getUserByToken(authData.apiToken).then((response) => {
          if(!IsEmpty(response)){
            let storeUser: IStoreUser = {
              id: response.id,
              userName: response.userName,
              email: response.email,
              phoneNumber: response.phoneNumber,
              firstName: response.firstName,
              lastName: response.lastName
            }
    
            dispatch(setUser(storeUser))
          }
        })
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <LoadingScreen/>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/booking-room" element={<Room />} />
          <Route path="/booking-room/detail/:roomID" element={<RoomDetail />} />
          <Route path="/rental" element={<Rental />} />
          <Route path="/rental/detail/:serviceID" element={<ServiceDetail />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/service-us" element={<ServiceUs />} />
          <Route path="/attraction-place" element={<AttractionPlacePage />} />
          <Route path="/payment/result" element={<ResultPage />} />

          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </div>
     
    </BrowserRouter>
  );
}

export default App;
