import './css/Footer.css'

function Footer() {
    return (   
        <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6" style={{textAlign: 'left'}}>
              <h1 style={{color: '#70d9ff'}}>PH.</h1>
              <p className="text-justify">We provide travel services such as hotel booking, homestay, car booking,.. if you are planning to travel, then come to us to choose for yourself the most attractive services.</p>
            </div>
  
            <div className="col-xs-6 col-md-3" style={{textAlign: 'left'}}>
              <h6>Quick Links</h6>
              <ul className="footer-links">
                <li><a href="/home">Trang chủ</a></li>
                <li><a href="/booking-room">Đặt phòng</a></li>
                <li><a href="/rental">Đặt dịch vụ</a></li>
                <li><a href="/payment">Thanh toán</a></li>
                <li><a href="/attraction-place">Địa điểm hấp dẫn</a></li>
              </ul>
            </div>
            <div className="col-xs-6 col-md-3" style={{textAlign: 'left'}}>
              <h6>Contact US</h6>
              <ul className="footer-links" >
                <li>(+84) 935551287</li>
                <li>51603117@student.tdtu.edu.vn</li>
                <li>Ton Duc Thang University</li>
              </ul>
            </div>
          </div>
          <hr></hr>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="" style={{textAlign: 'left'}}>Copyright &copy; 2023 DA CNTT2 Phu Hoa</p>
            </div>
  
            <div className="col-md-4 col-sm-6 col-xs-12">
              <ul className="social-icons">
                <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
                <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
                <li><a className="dribbble" href="#"><i className="fa fa-dribbble"></i></a></li>
                <li><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>   
              </ul>
            </div>
          </div>
        </div>
  </footer>
    );
  }
  
  export default Footer;
  