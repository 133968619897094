import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getUserByToken, login } from './core/service';
import { IsEmpty } from '../../core/utilities/functionBase';
import { saveAuth } from './core/authHelpers';
import { useDispatch } from 'react-redux';
import { IStoreUser, setUser } from '../../core/controllers/ReduxStore';
import './css/Auth.css'

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [txtUserName, setTxtUserName] = useState('')
  const [txtPassword, setTxtPassword] = useState('')
  const [isLoginClick, setIsLoginClick] = useState(false)
  const [isError, setIsError] = useState(false)
  const [txtError, setTxtError] = useState('')

  //onst userData = useAppSelector((state) => state.user)
  //console.log(userData)

  const onChangeUsername = (event: any) => {
    setIsError(false)
    setTxtError('')
    setTxtUserName(event.target.value)
  }

  const onChangePassword = (event: any) => {
    setIsError(false)
    setTxtError('')
    setTxtPassword(event.target.value)
  }

  const onClickSunmit = async () => {
    setIsLoginClick(true)

    if(!txtUserName || !txtPassword){
      setIsLoginClick(false)
      return;
    }

    const auth = await login(txtUserName, txtPassword)
    if(!IsEmpty(auth)){
      const user = await getUserByToken(auth.apiToken)
      if(!IsEmpty(user)){
        let storeUser: IStoreUser = {
          id: user.id,
          userName: user.userName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          firstName: user.firstName,
          lastName: user.lastName
        }
  
        dispatch(setUser(storeUser))
        saveAuth(auth)
        navigate("/home");
      }
    }
  
    setIsError(true)
    setTxtError('Error! The login detail is incorrect')
    setIsLoginClick(false)
  }

  return (
    <div className='container-auth'>
        <div className="center-auth">
        <h1>Login</h1>
        <form className='divForm'>
          <div className="txt_field-auth">
            <input type="text" value={txtUserName} onChange={onChangeUsername} required></input>
            <span></span>
            <label>Email</label>
          </div>
          <div className="txt_field-auth">
            <input type="password" value={txtPassword} onChange={onChangePassword} required></input>
            <span></span>
            <label>Mật khẩu</label>
          </div>
          <p style={{color:'red'}} hidden={!isError}>{txtError}</p>
          {isLoginClick && <button className='btn-auth' disabled><i className="fa fa-spinner fa-spin"></i> Đăng nhập</button>}
          {!isLoginClick && <button onClick={onClickSunmit} className='btn-auth'>Đăng nhập</button>}
          <div className="signup_link-auth">
            Not a member? <Link to="/sign-up">Sign up</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;