import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import { removeAuth } from './core/authHelpers';

export function Logout() {
    useEffect(() => {
        removeAuth()
        document.location.reload()
    })

    return (
        <Navigate to='/login' />
    )
}