import { ServiceOption } from '../../../core/options/serviceOption';
import { Post } from '../../../core/services/serviceBase';
import { BookingResponse, RoomModel, TouristPlaceModel } from './models';

const API_URL = process.env.REACT_APP_API_URL
export const TOP_SEARCH_URL = `${API_URL}/Room/TopSearch`
export const SEARCH_URL = `${API_URL}/Room/Search`
export const QUERY_URL = `${API_URL}/Room/Query`
export const VALIDATE_URL = `${API_URL}/Room/ValidateDateTime`
export const SEARCH_TOURIST_PLACE_URL = `${API_URL}/TouristPlace/Search`
export const SAVE_BOOKING_URL = `${API_URL}/Booking/Save`

export async function topSearchRoom() {
    const response = await Post(TOP_SEARCH_URL, {})
    let responseData = {} as Array<RoomModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function searchRoom(city: string, people: string, checkIn: string | "0", checkOut: string | "0") {
    const response = await Post(SEARCH_URL, {city, people, checkIn, checkOut})
    let responseData = {} as Array<RoomModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function queryRoom(roomID: number) {
    const response = await Post(QUERY_URL, {roomID})
    let responseData = {} as RoomModel;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function validate(room: number, fromDate: string, toDate: string) {
    const response = await Post(VALIDATE_URL, {room, fromDate, toDate})
    
    return response?.responseCode === ServiceOption.Success
}



export async function searchTouristPlaces(cityCode: string) {
    const response = await Post(SEARCH_TOURIST_PLACE_URL, {cityCode})
    let responseData = {} as Array<TouristPlaceModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}

export async function saveBooking(
    roomID: number, 
    amount: string,
    fullName: string, 
    phoneNumber: string, 
    email: string, 
    dateCheckIn: string, 
    dateCheckOut: string, 
    description: string, 
    paymentMethod: string, 
    userIDCreate: number
    ) {

    const response = await Post(SAVE_BOOKING_URL, {
        roomID, 
        amount,
        fullName, 
        phoneNumber, 
        email, 
        dateCheckIn, 
        dateCheckOut, 
        description, 
        paymentMethod, 
        userIDCreate}
    )
    
    let responseData = {} as BookingResponse;
    
    if(response?.responseCode === ServiceOption.Success){
        responseData = response.responseData;
    }
    
    return responseData
}