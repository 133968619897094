import axios from 'axios';
import { ServiceOption } from '../../../core/options/serviceOption';
import { Post } from '../../../core/services/serviceBase';

const API_URL = process.env.REACT_APP_API_URL
export const CONTACT_URL = `${API_URL}/Support/Contact`

export async function createContact(name: string, email: string, phoneNumber: string, content: string ) {
    const response = await Post(CONTACT_URL, { name, email, phoneNumber, content })
    
    return response?.responseCode === ServiceOption.Success
}