import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { citySlice, userSlice } from './core/controllers/ReduxStore';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const appStore = configureStore({
  reducer: {
    user: userSlice.reducer,
    city: citySlice.reducer
  },

});

type RootState = ReturnType<typeof appStore.getState>
type AppDispatch = typeof appStore.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


root.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
