import axios from 'axios';
import { ServiceOption } from '../../../core/options/serviceOption';
import { Post } from '../../../core/services/serviceBase';
import { AuthModel, UserModel} from './models';

const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_URL = `${API_URL}/Auth/Login`
export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/Auth/GetToken`
export const REGISTER_URL = `${API_URL}/Auth/Register`

export async function getUserByToken(token: string) {
    const response = await Post(GET_USER_BY_ACCESS_TOKEN_URL, { apiToken: token })
    let userData = {} as UserModel;
    
    if(response?.responseCode === ServiceOption.Success){
        userData = response.responseData;
    }
    
    return userData
}

export async function login(userName: string, password: string) {
    const response = await Post(LOGIN_URL, { userName, password })
    let authData = {} as AuthModel;

    if(response?.responseCode === ServiceOption.Success){
        authData = response.responseData;
    }
    
    return authData;
}

export async function register(
    firstname: string,
    lastname: string,
    email: string,
    phoneNumber: string,
    password: string,
    password_confirmation: string
) {
    let requestData = {
        userName: email,
        lastName: lastname,
        firstName: firstname,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
        passwordConfirmation: password_confirmation
    }

    const response = await Post(REGISTER_URL, requestData)
    let authData = {} as AuthModel;

    if(response?.responseCode === ServiceOption.Success){
        authData = response.responseData;
    }
    
    return authData;
}