import { useEffect } from 'react';
import Aos from 'aos';
import Header from '../base/Header';
import Footer from "../base/Footer";
import { 
    Autocomplete,
    Box,
    Button, 
    Card, 
    CardActionArea, 
    CardContent, 
    CardMedia, 
    Container, 
    Grid, 
    Link, 
    TextField, 
    Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CityModel } from '../base/core/models';
import { getCity } from '../base/core/service';
import { getServices, searchServices, topSearchServices } from './core/service';
import { ServiceModel } from './core/models';
import { closeLoading, showLoading } from '../loading/LoadingScreen';

function Rental() {
    const navigator = useNavigate()

    const [serviceData, setServiceData] = React.useState<Array<ServiceModel>>();
    const getOptionServiceLabel = (option: ServiceModel) => option.serviceName;
    const getOptionServiceValue = (option: ServiceModel, value: any) => option.serviceID === value.equipmentID || value === "";
    const [serviceValue, setServiceValue] = React.useState<ServiceModel | null>(null);

    const [cityData, setCityData] = React.useState<Array<CityModel>>();
    const getOptionCityLabel = (option: CityModel) => option.cityName;
    const getOptionCityValue = (option: CityModel, value: any) => option.cityCode === value.cityCode || value === "";
    const [cityValue, setCityValue] = React.useState<CityModel | null>(null);

    const [searchServiceData, setSearchServiceData] = React.useState<Array<ServiceModel>>();
    const [topServiceData, setTopServiceData] = React.useState<Array<ServiceModel>>();
    
    useEffect(() => {
        showLoading()
        Aos.init({duration: 2000})

        getCity().then((response) => {
            setCityData(response)
        })

        getServices().then((response) => {
            setServiceData(response)
        })

        topSearchServices().then((response) => {
            setTopServiceData(response)
            closeLoading()
        })
    },[])

    const search = () => {
        showLoading()
        let service = serviceValue ? serviceValue?.serviceID : 0
        let city = cityValue ? cityValue?.cityCode : ""

        searchServices(city, service).then((response) => {
            setSearchServiceData(response)
            closeLoading()
        })
    }

    const showServiceDetail = (serviceID: number) => {
        navigator(`/rental/detail/${serviceID}`)
    }

    return (
        <div>
            <Header isHome={false} page='rental'/>
            <div style={{height: '68px', backgroundColor: 'rgb(41, 50, 60)'}}></div>
            <Container style={{marginTop: '20px', marginBottom: '100px'}}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Box sx={{ flexGrow: 1, marginTop: '30px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        {cityData && cityData?.length > 0 &&
                                            <Autocomplete
                                                options={cityData}
                                                value={cityValue}
                                                getOptionLabel={getOptionCityLabel}
                                                isOptionEqualToValue={getOptionCityValue}
                                                onChange={(event: any, newValue: CityModel | null) => {
                                                    setCityValue(newValue)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Địa điểm" variant="standard" />
                                                )}
                                            />
                                        } 
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        {serviceData && serviceData?.length > 0 &&
                                            <Autocomplete
                                                options={serviceData}
                                                value={serviceValue}
                                                getOptionLabel={getOptionServiceLabel}
                                                isOptionEqualToValue={getOptionServiceValue}
                                                onChange={(event: any, newValue: ServiceModel | null) => {
                                                    setServiceValue(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Dịch vụ" variant="standard" />
                                                )}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button style={{marginTop: '16px'}} className="btn-booking" variant="contained" onClick={search}>
                                            Tìm kiếm
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{marginTop: '16px'}}>
                                    {searchServiceData && searchServiceData?.length > 0 &&
                                        searchServiceData.map((service: ServiceModel) => (
                                            <Grid item xs={12} md={6} key={service.serviceID}>
                                                <Card className="card-top-search">
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ width: "75%", height: "115px" }}
                                                        image={service.serviceImages[0].imageUrl}
                                                        alt="Live from space album cover"
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                                        <CardContent style={{ paddingBottom: 0 }}>
                                                            <Typography component="div" variant="h6">
                                                                <Link className="card-top-search-title" href={`/rental/detail/${service.serviceID}`} target="_blank">
                                                                    {service.serviceName}
                                                                </Link>
                                                            </Typography>
                                                            <div>
                                                                <span>Giá: <b>{service.displayPrice} VND</b></span>
                                                            </div>
                                                            <Button style={{color: 'rgb(1 128 80)'}} 
                                                                size="small" 
                                                                onClick={() => showServiceDetail(service.serviceID)}
                                                            >
                                                                Chi tiết
                                                            </Button>
                                                        </CardContent>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{borderBottom: '2px solid rgb(188 190 190)', marginTop: '46px', textAlign: 'left', fontSize: '20px'}}>
                                <b>Dịch vụ nổi bật</b>
                            </div>
                            <div style={{
                                width: '100%',
                                height: '600px',
                                overflow: 'scroll'
                            }}>
                                {topServiceData && topServiceData?.length > 0 &&
                                    topServiceData.map((service: ServiceModel) => (
                                        <Card sx={{ marginTop: '25px', marginRight: '5px', marginLeft: '5px'}} key={service.serviceID}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={service.serviceImages[1].imageUrl}
                                                    alt="green iguana"
                                                    />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h6" component="div">
                                                        <Link className="card-top-search-title" href={`/rental/detail/${service.serviceID}`} target="_blank">
                                                            {service.serviceName}
                                                        </Link>
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer/>
        </div>
    );
}

export default Rental;