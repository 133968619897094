import { 
    Box, 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Grid, 
    IconButton, 
    Typography, 
    styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import React, { forwardRef, useImperativeHandle } from 'react';
import { saveOrder } from './core/service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const PaymentOrder = forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState(0);
    const [orderID, setOrderID] = React.useState(0);
    const [branchID, setBranchID] = React.useState(0);
    const [orderType, setOrderType] = React.useState("");
    const [fullName, setFullName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [orderName, setOrderName] = React.useState("");
    const [dateCheckIn, setDateCheckIn] = React.useState(0);
    const [dateCheckOut, setDateCheckOut] = React.useState(0);
    const [dateCheckInDisplay, setDateCheckInDisplay] = React.useState("");
    const [dateCheckOutDisplay, setDateCheckOutDisplay] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [count, setCount] = React.useState(0);
    const [totalAmount, setTotalAmount] = React.useState("");
    const [paymentMethod, setPaymentMethod] = React.useState("");
    const [isConfirm, setIsConfirm] = React.useState(false);

    useImperativeHandle(ref, () => ({
        handleClickOpen : (
            user: number,
            orderID: number,
            branchID: number,
            orderType: string,
            fullName: string,
            phoneNumber: string,
            email: string,
            orderName: string,
            dateCheckIn: number,
            dateCheckOut: number,
            dateCheckInDisplay: string,
            dateCheckOutDisplay: string,
            description: string,
            count: number,
            totalAmount: number,
            paymentMethod: string
        ) : void => {
            setUser(user)
            setOrderID(orderID)
            setBranchID(branchID)
            setOrderType(orderType)
            setFullName(fullName)
            setEmail(email)
            setPhoneNumber(phoneNumber)
            setOrderName(orderName)
            setDateCheckIn(dateCheckIn)
            setDateCheckOut(dateCheckOut)
            setDateCheckInDisplay(dateCheckInDisplay)
            setDateCheckOutDisplay(dateCheckOutDisplay)
            setDescription(description)
            setCount(count)
            setPaymentMethod(paymentMethod)

            const config = { style: 'currency', currency: 'VND', maximumFractionDigits: 9}
            const formated = new Intl.NumberFormat('vi-VN', config).format(totalAmount);
            setTotalAmount(formated.replace('₫', 'VND'))

            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const submitOrder = () => {
        setIsConfirm(true)
        saveOrder(
            orderID,
            orderName,
            branchID,
            orderType,
            totalAmount.replace(/[^0-9]/g, ''),
            fullName,
            phoneNumber,
            email,
            dateCheckIn,
            dateCheckOut,
            description,
            count,
            paymentMethod,
            user)
        .then((response) => {
            setIsConfirm(false)
            handleClose();
            if(response){
                window.location.replace(response.endpoint);
            }
        })
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Thông tin đơn hàng
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    <Box sx={{ flexGrow: 1, padding: '20px'}}>
                        <Grid container spacing={2} style={{textAlign: 'left'}}>
                            <Grid item xs={12} md={1}></Grid>
                            <Grid item xs={6} md={5}>
                                <label>Họ và tên:</label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{fullName}</b>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>

                            <Grid item xs={12} md={1}></Grid>
                            <Grid item xs={6} md={5}>
                                <label>Email:</label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{email}</b>
                            </Grid> 
                            <Grid item xs={12} md={1}></Grid>

                            <Grid item xs={12} md={1}></Grid> 
                            <Grid item xs={6} md={5}>
                                <label>Số điện thoại:</label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{phoneNumber}</b>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>
                        </Grid>
                    </Box>
                </Typography>
                <Typography gutterBottom>
                    <Box sx={{ flexGrow: 1, padding: '20px'}}>
                        <Grid container spacing={2} style={{textAlign: 'left'}}>
                            <Grid item xs={12} md={1}></Grid>
                            <Grid item xs={6} md={5}>
                                <label> {orderType === "ROOM" ? "Khách sạn:" : "Dịch vụ:"} </label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{orderName}</b>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>

                            <Grid item xs={12} md={1}></Grid>
                            <Grid item xs={6} md={5}>
                                <label>Số lượng: </label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{count}</b>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>

                            <Grid item xs={12} md={1}></Grid>
                            <Grid item xs={6} md={5}>
                                <label> {orderType === "ROOM" ? "Ngày nhận phòng:" : "Ngày nhận:"} </label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{dateCheckInDisplay}</b>
                            </Grid> 
                            <Grid item xs={12} md={1}></Grid>

                            <Grid item xs={12} md={1}></Grid> 
                            <Grid item xs={6} md={5}>
                                <label>{orderType === "ROOM" ? "Ngày trả phòng:" : "Ngày trả:"} </label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{dateCheckOutDisplay}</b>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>

                            <Grid item xs={12} md={1}></Grid> 
                            <Grid item xs={6} md={5}>
                                <label>Nội dung</label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                {description}
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>
                        </Grid>
                    </Box>
                </Typography>
                <Typography gutterBottom>
                    <Box sx={{ flexGrow: 1, padding: '20px'}}>
                        <Grid container spacing={2} style={{textAlign: 'left'}}>
                            <Grid item xs={12} md={1}></Grid>
                            <Grid item xs={6} md={5}>
                                <label>Tổng tiền: </label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{totalAmount}</b>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>

                            <Grid item xs={12} md={1}></Grid>
                            <Grid item xs={6} md={5}>
                                <label>Phương thức thanh toán: </label>
                            </Grid>
                            <Grid item xs={6} md={5} style={{ display: 'flex' }}>
                                <b>{paymentMethod}</b>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>
                        </Grid>
                    </Box>
                </Typography>
                </DialogContent>
            <DialogActions>
            <Button variant="outlined" 
                onClick={submitOrder}
                disabled={isConfirm}
            >
                {isConfirm && <i className="fa fa-spinner fa-spin" style={{marginRight: '5px'}}></i>}
                    Xác nhận
            </Button>
            </DialogActions>
        </BootstrapDialog>
    );
})

export { PaymentOrder } 