import { useEffect } from 'react';
import Header from '../base/Header';
import Footer from "../base/Footer";
import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button, 
    Container, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Grid, 
    TextField, 
    Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { paymentOrder, queryOrder } from './core/service';
import { OrderModel } from './core/models';
import { useAppSelector } from '../..';
import { getAuth } from '../auth/core/authHelpers';
import { AuthModel } from '../auth/core/models';
import { useNavigate } from 'react-router-dom';

function Payment() {
    const navigator = useNavigate()
    const authData: AuthModel | undefined = getAuth();
    const userData = useAppSelector((state) => state.user);
    const [open, setOpen] = React.useState(false);
    const [txtValue, setValue] = React.useState("");
    const [txtErrorMessage, setErrorMessage] = React.useState("");
    const [expandCustomer, setExpandCustomer] = React.useState(true);
    const [expandOrder, setExpandOrder] = React.useState(true);
    const [expandPayment, setExpandPayment] = React.useState(true);
    const [orderData, setOrderData] = React.useState<OrderModel>();
    const [isPayment, setIsPayment] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigator("/login")
    };

    useEffect(() => {
        if(!authData || !userData){
            handleClickOpen()
        }
    }, [])

    const search = () => {
        if(!authData || !userData){
            handleClickOpen()
            return;
        }

        if(!txtValue){
            setErrorMessage("Vui lòng nhập thông tin.");
            return;
        }

        queryOrder(txtValue, userData.id).then((response) => {
            if(response){
                setOrderData(response)
                setErrorMessage("");
                return;
            }

            setErrorMessage("Vui lòng kiểm tra thông tin");
        })
    }

    const payment = () => {
        setIsPayment(true)

        if(orderData && userData){
            paymentOrder(orderData?.orderNumber,userData.id).then((response) => {
                if(response){
                    window.location.replace(response.endpoint);
                }
                setIsPayment(false)
            })
        }
    }

    return (
        <div>
            <Header isHome={false} page='payment'/>
            <div style={{height: '68px', backgroundColor: 'rgb(41, 50, 60)'}}></div>
            <Container style={{marginTop: '20px', marginBottom: '100px', margin: '0 auto', paddingBottom: '700px'}}>
                <Box sx={{ flexGrow: 1, marginTop: '50px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{width: '300px'}}>
                                <TextField
                                    fullWidth
                                    label="Mã hóa đơn"
                                    id="txtOrderID"
                                    size="small"
                                    onChange={(event) => setValue(event.target.value)}
                                    style={{backgroundColor: "#fff"}}
                                />
                                <div style={{color: "red", textAlign: 'left', marginLeft: '10px', fontSize: '13px'}}>{txtErrorMessage}</div>
                            </div>
                            <div style={{marginLeft: '10px', marginTop: '1px'}}>
                                <Button style={{backgroundColor: "rgb(236, 164, 19)"}} 
                                    variant="contained" 
                                    size="medium"
                                    onClick={search}
                                >
                                    Tìm kiếm
                                </Button>
                            </div>
                        </Grid>  
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, marginTop: '50px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            { orderData &&
                                <div>
                                    <Accordion expanded={expandCustomer} onChange={() => setExpandCustomer(!expandCustomer)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{borderBottom: '1px solid #e6e6e6'}}
                                        >
                                            <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'left' }}>
                                                <b style={{color: '#adadad'}}>Thông tin khách hàng</b>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{ flexGrow: 1, padding: '20px'}}>
                                                <Grid container spacing={2} style={{textAlign: 'left'}}>
                                                    <Grid item xs={6} md={2}>
                                                        <label>Họ và tên:</label>
                                                    </Grid>
                                                    <Grid item xs={6} md={4} style={{ display: 'flex' }}>
                                                        <b>{orderData.fullName}</b>
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        <label>Email:</label>
                                                    </Grid>
                                                    <Grid item xs={6} md={4} style={{ display: 'flex' }}>
                                                        <b>{orderData.email}</b>
                                                    </Grid>  
                                                    <Grid item xs={6} md={2}>
                                                        <label>Số điện thoại:</label>
                                                    </Grid>
                                                    <Grid item xs={6} md={4} style={{ display: 'flex' }}>
                                                        <b>{orderData.phoneNumber}</b>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expandOrder} onChange={() => setExpandOrder(!expandOrder)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{borderBottom: '1px solid #e6e6e6'}}
                                        >
                                            <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'left' }}>
                                                <b style={{color: '#adadad'}}>
                                                    {
                                                        orderData.orderType.toUpperCase() === "ROOM" 
                                                            ? "Thông tin Khách sạn/ Homestay" 
                                                            : "Thông tin Dịch vụ"
                                                    }
                                                </b>
                                            </Typography>
                                        </AccordionSummary>`
                                        <AccordionDetails>
                                            <Typography>
                                                <Box sx={{ flexGrow: 1, padding: '20px'}}>
                                                    <Grid container spacing={2} style={{textAlign: 'left'}}>
                                                        <Grid item xs={6} md={2}>
                                                            <label>{
                                                                orderData.orderType.toUpperCase() === "ROOM" 
                                                                    ? "Tên phòng:" 
                                                                    : "Tên dịch vụ:"
                                                            }</label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.orderReferenceName}</b>
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <label>
                                                                {
                                                                    orderData.orderType.toUpperCase() === "ROOM" 
                                                                        ? "Ngày nhận phòng:" 
                                                                        : "Ngày nhận:"
                                                                }
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.dateCheckIn}</b>
                                                        </Grid> 

                                                        <Grid item xs={6} md={2}>
                                                            <label>Thời gian tạo:</label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.dateTimeCreate}</b>
                                                        </Grid> 
                                                        <Grid item xs={6} md={2}>
                                                            <label>
                                                                {
                                                                    orderData.orderType.toUpperCase() === "ROOM" 
                                                                        ? "Ngày trả phòng:" 
                                                                        : "Ngày trả:"
                                                                }
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.dateCheckOut}</b>
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <label>Số lượng: </label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.count}</b>
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <label>Nội dung: </label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.description}</b>
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <label>Trạng thái: </label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.status}</b>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expandPayment} onChange={() => setExpandPayment(!expandPayment)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{borderBottom: '1px solid #e6e6e6'}}
                                        >
                                            <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'left' }}>
                                                <b style={{color: '#adadad'}}>Thông tin thanh toán</b>
                                            </Typography>
                                        </AccordionSummary>`
                                        <AccordionDetails>
                                            <Typography>
                                                <Box sx={{ flexGrow: 1, padding: '20px'}}>
                                                    <Grid container spacing={2} style={{textAlign: 'left'}}>
                                                        <Grid item xs={6} md={2}>
                                                            <label>Mã đặt phòng/ dịch vụ:</label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.orderNumber}</b>
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <label>Mã thanh toán:</label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.transactionID}</b>
                                                        </Grid>  
                                                        <Grid item xs={6} md={2}>
                                                            <label>Phương thức thanh toán:</label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.paymentMethod}</b>
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <label>Trạng thái thanh toán:</label>
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <b>{orderData.paymentStatus === 'INIT' && 'Chưa thanh toán'}</b>
                                                            <b>{orderData.paymentStatus !== 'INIT' && 'Đã thanh toán'}</b>
                                                        </Grid>
                                                        {   orderData.paymentStatus === 'INIT' && orderData.status !== 'DELETE' &&
                                                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                                                <Button style={{backgroundColor: "rgb(25 213 235)"}} 
                                                                    variant="contained"
                                                                    onClick={payment}
                                                                    disabled={isPayment}
                                                                >
                                                                    {isPayment && <i className="fa fa-spinner fa-spin" style={{marginRight: '5px'}}></i>}
                                                                    Thanh toán
                                                                </Button>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }
                        </Grid>
                        <Grid item xs={12} md={1}></Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Bạn chưa đăng nhập?
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Vui lòng đăng nhập để thực hiện thanh toán.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Xác nhận
                    </Button>
                    </DialogActions>
                </Dialog>
            </Container>
            <Footer/>
        </div>
    );
}

export default Payment;