import { ServiceOption } from '../../../core/options/serviceOption';
import { Post } from '../../../core/services/serviceBase';
import { CityModel, TouristAttractionModel } from './models';

const API_URL = process.env.REACT_APP_API_URL
export const GET_CITY_URL = `${API_URL}/Support/GetCity`
export const GET_TOURIST_ATTRACTION_URL = `${API_URL}/Support/GetTouristAttraction`

export async function getCity() {
    const response = await Post(GET_CITY_URL, {})
    let cityData = {} as Array<CityModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        cityData = response.responseData;
    }
    
    return cityData
}

export async function getTouristAttraction() {
    const response = await Post(GET_TOURIST_ATTRACTION_URL, {})
    let touristAttractionData = {} as Array<TouristAttractionModel>;
    
    if(response?.responseCode === ServiceOption.Success){
        touristAttractionData = response.responseData;
    }
    
    return touristAttractionData
}