import { useState } from 'react';
import './css/ContactUs.css'
import { createContact } from './core/service';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function Contact() {
    const [txtName, setTxtName] = useState('')
    const [txtEmail, setTxtEmail] = useState('')
    const [txtPhoneNumber, setTxtPhoneNumber] = useState('')
    const [txtContent, setTxtContent] = useState('')
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChangeName = (event: any) => {
        setTxtName(event.target.value)
    }

    const onChangeEmail = (event: any) => {
        setTxtEmail(event.target.value)
    }

    const onChangePhoneNumber = (event: any) => {
        setTxtPhoneNumber(event.target.value)
    }

    const onChangeContext = (event: any) => {
        setTxtContent(event.target.value)
    }

    const onClickContact = (e: any) => {
        e.preventDefault();
        if(!txtName || !txtEmail || !txtPhoneNumber || !txtContent){
            return;
        }
        createContact(txtName, txtEmail, txtPhoneNumber,txtContent)
        handleClickOpen()
    }

    return (
        <div style={{marginBottom: '100px'}}>
            <div className="container-contact-us">
                <div className="contact-box-contact-us">
                <div className="left-contact-us"></div>
                    <div className="right-contact-us">
                        <h2 className="title-contact-us">Contact Us</h2>
                        <form onSubmit={onClickContact}>
                            <input type="text" className="field-contact-us" placeholder="Your Name" value={txtName} onChange={onChangeName} required></input>
                            <input type="email" className="field-contact-us" placeholder="Your Email" value={txtEmail} onChange={onChangeEmail} required></input>
                            <input type="text" className="field-contact-us" placeholder="Phone" value={txtPhoneNumber} onChange={onChangePhoneNumber} required></input>
                            <textarea placeholder="Message" className="field-contact-us" value={txtContent} onChange={onChangeContext} required></textarea>
                            <button type='submit' className="btn-send-contact-us">Send</button>
                        </form>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cảm ơn bạn đã liên hệ với chúng tôi!
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Chúng tôi sẽ trả lời cho bạn sớm nhất có thể
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Đóng
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Contact;