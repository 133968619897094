import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Header from '../base/Header';
import Footer from "../base/Footer";
import './css/AboutUs.css';
import Aos from 'aos';

function AboutUs() {
    useEffect(() => {
        Aos.init({duration: 2000})
    })

    return (
        <div>
            <Header isHome={true} page='about-us'/>
            <div id="demo" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="/media/core/background1.jpeg" height='800px' alt="Los Angeles" className="d-block" width='100%'></img>
                    </div>
                    <div className="carousel-item">
                        <img src="/media/core/background2.jpeg" height='800px' alt="Chicago" className="d-block" width='100%'></img>
                    </div>
                    <div className="carousel-item">
                        <img src="/media/core/background3.jpeg" height='800px' alt="New York" className="d-block" width='100%'></img>
                    </div>
                </div>
                <div className="slide-text-welcome">
                    <h1 data-aos="fade-up">About us</h1>
                    <p data-aos="fade-up"> where the best options for you</p> 
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>
            <div className='container' style={{paddingTop: '30px', paddingBottom: '70px'}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid className='about-gird-item-content' container spacing={5}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid data-aos="fade-up" item xs={12} md={3}>
                            &emsp;Chào mừng đến với trang web đặt phòng khách sạn của chúng tôi! Chúng tôi rất vui khi có bạn ở đây 
                            và rất nóng lòng được giúp bạn lên kế hoạch cho chuyến đi tiếp theo. Trang web của chúng tôi được thiết kế 
                            để giúp bạn dễ dàng tìm và đặt khách sạn hoàn hảo phù hợp với nhu cầu của mình, bất kể bạn đang đi du lịch 
                            ở đâu hay ngân sách của bạn là bao nhiêu.
                        </Grid>
                        <Grid data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={3}>
                            <img src="/media/core/aboutUs3.webp" alt='' height="180px"></img>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                    <Grid className='about-gird-item-content' container spacing={5}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3} data-aos="fade-up" style={{display: 'flex', justifyContent: 'center'}}>
                            <img src="/media/core/aboutUs4.jpeg" alt='' height="180px"></img>
                        </Grid>
                        <Grid data-aos="fade-up" item xs={12} md={3}>
                            &emsp;Với giao diện thân thiện với người dùng và các bộ lọc tìm kiếm toàn diện của chúng tôi, bạn có thể nhanh chóng 
                            thu hẹp các tùy chọn của mình và tìm các khách sạn đáp ứng các yêu cầu cụ thể của mình. Cho dù bạn đang tìm kiếm một 
                            khu nghỉ dưỡng sang trọng, một nhà nghỉ hợp túi tiền hay bất cứ thứ gì ở giữa, chúng tôi đều đáp ứng được cho bạn.
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                    <Grid className='about-gird-item-content' container spacing={5}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid data-aos="fade-up" item xs={12} md={6}>
                            &emsp;Trang web của chúng tôi cũng cung cấp mức giá và phòng trống theo thời gian thực, vì vậy bạn có thể yên tâm rằng 
                            thông tin bạn đang xem là chính xác và cập nhật. Ngoài ra, chúng tôi làm việc với một số khách sạn và nhà cung cấp dịch vụ 
                            khách sạn hàng đầu trên thế giới, đảm bảo rằng bạn có quyền truy cập vào các tùy chọn tốt nhất hiện có.
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                    <Grid className='about-gird-item-content' container>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid data-aos="fade-up" item xs={12} md={6}>
                            &emsp;Tại trang web đặt phòng khách sạn của chúng tôi, chúng tôi tự hào về việc cung cấp dịch vụ khách hàng đặc biệt. 
                            Nhóm của chúng tôi luôn sẵn sàng trả lời bất kỳ câu hỏi nào bạn có thể có và giúp bạn điều hướng quá trình đặt phòng. 
                            Cho dù bạn cần hỗ trợ với một khách sạn cụ thể, có câu hỏi về trang web của chúng tôi hoặc cần trợ giúp đặt phòng, 
                            chúng tôi luôn sẵn sàng trợ giúp.
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                    <Grid className='about-gird-item-content' container spacing={5}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid data-aos="fade-up" item xs={12} md={6}>
                            &emsp;Cảm ơn bạn đã chọn trang web đặt phòng khách sạn của chúng tôi cho nhu cầu du lịch của bạn. Chúng tôi mong được 
                            giúp bạn lên kế hoạch cho chuyến phiêu lưu tiếp theo và đảm bảo rằng bạn có một kỳ nghỉ thoải mái và thú vị ở bất 
                            cứ nơi nào mà chuyến đi đưa bạn đến.
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                </Box>
            </div>
            <Footer/>
        </div>
    );
}

export default AboutUs;